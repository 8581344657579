import { Component, OnInit, AfterViewInit, Injector, ViewChild, ViewChildren, HostListener, ElementRef, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Location as ngLocation } from '@angular/common';
import { NgForm, FormGroup } from '@angular/forms';
import { SafeUrl, DomSanitizer } from '@angular/platform-browser';
import * as _moment from 'moment';
import notify from 'devextreme/ui/notify';
import DataGrid from 'devextreme/ui/data_grid';
import { forkJoin, of, Subscription } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

// general services
import { LanguageService } from '../../shared/services/language.service';
import { DateTimeService } from '../../shared/services/datetime.service';

// components
import { FullListComponent } from '../../shared/components/full-list/full-list.component';
import { LotComponent } from './lot.component';
import { ConfirmationComponent } from '../../shared/components/confirmation/confirmation.component';

// services
import { CatalogService } from '../shared/services/catalog.service';
import { ProductService } from '../shared/services/product.service';
import { LotService } from '../shared/services/lot.service';
import { LookupTableService } from '../shared/services/lookup-table.service';
import { MaskService } from '../shared/services/mask.service';
import { CalculationsService } from '../shared/services/calculations.service';
import { TitleService } from '../../shared/services/title.service';

import { CookieService } from 'ngx-cookie-service'

// signalr
import { HubConnection, HubConnectionBuilder, LogLevel, JsonHubProtocol, IReconnectPolicy, HttpTransportType } from '@aspnet/signalr';

// models
import { Catalog } from '../shared/models/catalog';
import { Lot, LotProperty, LotPropertyGroup } from '../shared/models/lot';
import { Location } from '../shared/models/location';
import { Language } from '../../shared/models/language';
import { TranslatableFieldComponent } from '../../shared/components/translatable-field/translatable-field.component';
import { ImportSchema, ImportField } from '../../shared/import/import-schema';
import { LookupTable, LookupTableField, LookupTableRow } from '../shared/models/lookup-table';
import {
  Product, ProductPropertyTypes, GridProperty, ProductPropertyGroup, LotEditorProperty,
  AllowMultilpleValuesWithingFieldDependency, ProductPropertyEditorSelectionDetails, FocusTypeEnum,
  ActionOnNewLotEnum, EffectOfMaskOnDataEnum, DecimalTypes, DateTypes, SystemProductPropertyTypesEnum,
  MasterDataFieldTypeEnum,
  UrlTypes
} from '../shared/models/product';
import { MasterDataService } from '../shared/services/master-data.service';
import { MasterData, MasterDataValue, MasterDataListField } from '../shared/models/master-data';
import { AuctionClusterSupplier } from '../shared/models/auction-cluster-supplier';
import { PublishLotComponent } from './publish-lot.component';
import { Moment } from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { Calculation, CalculationFieldTypes, CalculationMathTypes, CalculationsField, CalculationFrontEndTypes } from '../shared/models/calculation';
import { ExportConstants } from '../../shared/constants/export.constants';
import { CSV_DATE_FORMAT } from '../../shared/constants/export.constants';
import { LotMultiEditComponent } from './lot-multi-edit.component';
import { DateTimeTypes } from '../../shared/models/dateTimeTypes';
import { GenericLotRow, GenericLotColumn } from '../../shared/models/generic-lot';

import { Cookies } from '../../shared/constants/cookies';
import { DxValidationGroupComponent } from 'devextreme-angular';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { WeighingScaleConnection } from '../shared/models/weighing-scale-connection';
import { AuctionClusterNotificationService } from '../shared/services/auction-cluster-notification.service';
import { AuctionClusterNotification } from '../../shared/models/auction-cluster-notification';
import { NotificationTypeEnum } from '../../shared/models/notificationTypeEnum';

const moment = (_moment as any).default ? (_moment as any).default : _moment;

const ENTER_KEY = 13;
const TAB_KEY = 9;
const SAVE_KEY = 83;
const TIMEOUT = 500;
const SAVE_TIMEOUT = 100;


export class MasterDataDropdown {
  value: any;
  label: string;
  img?: string;
}

enum ValidationErrorCode {
  MIN_STOP_PRICE_HIGHER_THAN_PREVIOUS_STOP_PRICE = 1000
}

@Component({
  selector: 'supply-lots-component',
  templateUrl: 'supply-lots.component.html',
  styleUrls: ['./supply-lots.component.scss']
})
export class SupplyLotsComponent extends FullListComponent<Lot, LotComponent> implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('confirmation') confirmation: any;
  @ViewChild('lotForm') lotForm: NgForm;
  @ViewChild('publishLotComponent') publishLotComponent: PublishLotComponent;
  @ViewChildren(TranslatableFieldComponent) translatableFields: Array<TranslatableFieldComponent> = [];
  @ViewChild('groupFilter') groupFilter: any;
  @ViewChild('dataGrid') dataGrid: any;
  @ViewChild('moveLots') moveLots: any;
  @ViewChild('multiEditComponent') multiEditComponent: LotMultiEditComponent;
  @ViewChild('mailSendMessage') mailSendMessage: ConfirmationComponent;
  @ViewChild('exportLink') exportLink: ElementRef;
  @ViewChild('validationFormGroup') validationFormGroup: DxValidationGroupComponent;
  @ViewChild('removeLotsConfirmation') removeLotsConfirmation: ConfirmationComponent;
  @ViewChild('lotAmountButton') deleteButton: ElementRef;
  @Output() onContentReady = new EventEmitter<string>();

  private _propertyComponents: any;
  @ViewChildren('propertyComponent')
  set propertyComponents(value: any) {
    if (this._propertyComponents === value) {
      return;
    }
    this._propertyComponents = value;

    // Force refocus previously focused property
    if (this.focusedProperty) {
      let propertyWithComponent = this.componentsWithProperties.find(_ => _.property.productPropertyId === this.focusedProperty.productPropertyId);
      if (propertyWithComponent) {
        this.focusedProperty = propertyWithComponent.property;
        this.focusedComponent = propertyWithComponent.component;

        if (this.focusedComponent instanceof ElementRef) {
          this.focusedComponent.nativeElement.focus();
        }
        else
          this.focusedComponent.instance.focus();

        // Autoselect any potential input field text
        let el = <any>document.activeElement;
        if (el && el.select)
          el.select();
      }
    }
  };

  get propertyComponents() {
    return this._propertyComponents;
  }

  componentsWithProperties: Array<{ component: any, property: LotProperty }> = [];
  focusedProperty: LotProperty;
  focusedComponent: any;

  private weighingScaleConnections: Array<WeighingScaleConnection> = [];
  private reconnectionTimeoutHandler = null;
  private messageReceiveTimeoutHandler = null;

  customDataSource: CustomStore;
  customDataSources: object = {};
  filteredDropdownIds: object = {};
  catalogs: Array<Catalog> = [];
  product: Product;
  locations: Array<Location> = [];
  suppliers: Array<AuctionClusterSupplier> = [];
  catalogId: number;
  catalog: Catalog;
  lot: Lot = new Lot();
  latestNewLot = new Lot();
  latestResetLot = new Lot();
  lotProperty: LotProperty;
  languages: Array<Language> = []
  clusterLanguages: Array<Language> = [];
  defaultClusterLanguage: Language;
  translatableValid = false;
  fields: HTMLCollectionOf<any>;
  fieldIndex: number;
  lastSupplier: number;
  lastLocation: number;
  numberErrorMessage = '';
  textErrorMessage = '';
  decimalErrorMessage = '';
  dateErrorMessage = '';
  masterDataErrorMessage = '';
  validationTranslation: any;
  lookupTables: Array<LookupTable> = [];
  masterDatas: Array<MasterData> = [];
  masterDataRowValues: Array<MasterDataDropdown> = [];
  lotAddingInProgress = false;
  masterDataDropdowns: Array<any> = [];
  masterDataErrors: Array<string> = [];
  supplyProperties: Array<GridProperty> = [];
  groupsWithProperties: Array<ProductPropertyGroup> = [];
  updateAllNumberOfPropsProcessed = 0;
  updateAllAppliedToAll = false;
  firstLoad = true;
  total: number;
  JSON: any;
  lang = 'nl-be';
  showActionsMessage = false;
  calculations: Array<Calculation> = [];
  currentDate: Date = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
  gridState: any;
  groupFiltersShowing = false;
  moveLotsShowing = false;
  gridRefreshing = false;
  isVertical = false;
  layoutWidth = 100;
  addNew = false;
  isFilling = false;
  savePending = false;

  masterDetailsLotId = null;

  masterDetailsColumns: Array<any> = [];
  masterDetailsData: Array<any> = new Array<any>();
  masterDetailsObjectKeys: Array<string> = new Array<string>();
  masterDetailsItems = [];
  masterDetailsCurrentPage = 1;
  masterDetailsLastRecordedPage = 1;
  masterDetailsPageSize: number;
  masterDetailsPageSizeValue = 50;
  isMasterDetailsShowing = false;

  exportConstants: ExportConstants = new ExportConstants();
  exportUrl: SafeUrl;
  exportFilename: string;
  confirmationErrorMessage: string;

  enableTouchScreenLayout: boolean = false;
  searchStartsWith: false;
  viewOnlyMode: boolean = false;
  deviceMasterDataListRowId: number;

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;
  dataGridMasterInstance: DataGrid;
  gridMasterColumns = [];
  gridMasterItems = [];
  dataGridInstance: DataGrid;
  gridColumns = [];
  gridItems = [];
  masterItems = [];
  lotItems = [];
  isFormValid = false;
  errorMessage: string;
  largeImageSrc: string;
  largerImageVisible = false;
  emptyPictureSource: string = '../../../assets/images/image_placeholder.jpg';
  itemsToRemove = [];
  isMultiEditorModeEnabled: boolean = false;
  isTouchMode: boolean = false;
  weightingScaleConnectionStatus: number = 0;
  auctionClusterNotification: AuctionClusterNotification;
  isSendNotificationDisabled: boolean = true;
  lastTimeSentNotification = '';

  get editorProperties(): Array<LotEditorProperty> {
    return this.product.supplyDataEditorProperties;
  }

  constructor(
    protected injector: Injector,
    private location: ngLocation,
    private dataService: LotService,
    private productService: ProductService,
    private catalogService: CatalogService,
    private languageService: LanguageService,
    private masterDataService: MasterDataService,
    private lookupTableService: LookupTableService,
    private maskService: MaskService,
    private translateService: TranslateService,
    private calculationService: CalculationsService,
    private dateTimeService: DateTimeService,
    private sanitizer: DomSanitizer,
    private titleService: TitleService,
    private cookieService: CookieService,
    private notificationService: AuctionClusterNotificationService
  ) {
    super(injector, Lot);
    this.JSON = JSON;
    this.lang = translateService.currentLang;
    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  ngOnInit() {
    this.isTouchMode = this.catalogService.setSupplyEditorMode(this.cookieService);
    this.catalogId = +this.route.snapshot.params['catalogId'];
    this.masterDetailsLotId = +this.route.snapshot.queryParams['masterDetailsLotId'];
    this.viewOnlyMode = this.route.snapshot.queryParams['viewOnlyMode'] === 'true';

    this.translate.get('VALIDATION').subscribe((res: string) => {
      this.validationTranslation = res;
    });

    this.translate.onLangChange.subscribe(() => {
      this.translate.get('VALIDATION').subscribe((res: string) => {
        this.validationTranslation = res;
      });
    });

    this.setTranslations('LOTS');
  }

  nextRetryDelayInMilliseconds(previousRetryCount: number, elapsedMilliseconds: number) {
    return 2000;
  }

  private createHubConnection(weighingScaleUrl: string) {
    //const userId = this.cookies.get(Cookies.USER_ID_COOKIE);

    let hubConnection = new HubConnectionBuilder()
      .withUrl(weighingScaleUrl, { transport: HttpTransportType.WebSockets, skipNegotiation: true, })
      .configureLogging(LogLevel.Error)
      .withHubProtocol(new JsonHubProtocol())
      .withAutomaticReconnect(<IReconnectPolicy>{
        nextRetryDelayInMilliseconds: this.nextRetryDelayInMilliseconds
      })
      .build();

    /*hubConnection.onreconnected((connectionId: string) => {
      
    });*/
    let existingConnection = this.weighingScaleConnections.find(_ => _.url === weighingScaleUrl);

    hubConnection.onclose(() => {
      this.reconnectionTimeoutHandler = setTimeout(() => this.startConnection(weighingScaleUrl), 2000);
      console.log('Reconnecting in 2 sec...');
      existingConnection.started = false;
      existingConnection.error = true;
    });

    hubConnection.on('ReceiveMessage', (response: string) => {
      clearTimeout(this.messageReceiveTimeoutHandler);
      let message = JSON.parse(response);
      this.updateWeighingScaleCache(weighingScaleUrl, message.Weight, message.Stable);
      this.messageReceiveTimeoutHandler = setTimeout(() => existingConnection.error = true, 5000);
    });

    return hubConnection;
  }

  updateWeighingScaleCache(weighingScaleUrl: string, weight: number, stable: boolean) {
    let existingConnection = this.weighingScaleConnections.find(_ => _.url === weighingScaleUrl);
    let previousStable = false;
    if (existingConnection) {
      previousStable = existingConnection.stable;
      existingConnection.weight = weight;
      existingConnection.stable = stable;

      let componentWithProperty = this.componentsWithProperties.find(_ => _.property.isWeighingScaleOn);
      if (componentWithProperty) {
        componentWithProperty.property.decimalValue = weight;
        let currentConnection = this.weighingScaleConnections.find(_ => _.productProperties.includes(componentWithProperty.property.productPropertyId));

        if (currentConnection.stable) {
          this.weightingScaleConnectionStatus = 1;
        } else if (!currentConnection.stable) {
          this.weightingScaleConnectionStatus = 2;
        } else if (currentConnection.error) {
          this.weightingScaleConnectionStatus = 3;
        }

        if (!previousStable && stable) {
          this.onPropertyChanged(componentWithProperty.property);
        }
      }
    }


  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._subscription.unsubscribe();
  }

  getLayoutWidht() {
    return `${this.layoutWidth}%`;
  }

  getGridWidth() {
    return `${100 - this.layoutWidth}%`;
  }

  getPropertyWidth(property: LotProperty) {
    return property.width && property.width > 0 ? `${property.width - 3}%` : '';
    //return property.width && property.width > 0 ? `33%` : '';
  }

  getPropertyMargin(property: LotProperty) {
    return property.width && property.width > 0 ? `3%` : '';
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.removeFocusableFromDropdowns();
      this.removeFocusableFromCheckbox();
      this.setFocus();
    }, TIMEOUT);
  }

  removeFocusableFromDropdowns() {
    const dds = document.querySelectorAll('input[role="combobox"]:not(.dx-texteditor-input)');
    for (let i = 0; i < dds.length; i += 1) {
      const ti = dds[i]
        && dds[i].parentElement
        && dds[i].parentElement.parentElement
        && dds[i].parentElement.parentElement.parentElement
        && dds[i].parentElement.parentElement.parentElement.parentElement
        && dds[i].parentElement.parentElement.parentElement.parentElement.getAttribute('tabindexforchildren') ?
        dds[i].parentElement.parentElement.parentElement.parentElement.getAttribute('tabindexforchildren') : '0';

      dds[i].setAttribute('tabIndex', ti);
      dds[i].setAttribute('class', 'focusable');
    }
  }

  removeFocusableFromCheckbox() {
    const cb = document.querySelectorAll('clr-checkbox input');
    for (let i = 0; i < cb.length; i += 1) {
      const ti = cb[i]
        && cb[i].parentElement
        && cb[i].parentElement.getAttribute('tabindexforchildren') ?
        cb[i].parentElement.getAttribute('tabindexforchildren') : '0';

      cb[i].setAttribute('tabIndex', ti);
      cb[i].setAttribute('class', 'focusable');
    }
  }

  manualRefresh(pageSize?: number, forceRefresh: boolean = false, lot?: Lot) {
    if (!lot) {
      lot = this.lot;
    }

    if (forceRefresh) {
      this.getData(null);
    } else {
      this.getData(lot);
    }

    this.reloadGrid();
  }

  refresh = (e: any) => {

  }


  // if newLot is set, we are already at loaded editor, no need to get all foreign data or empty newLot object
  getData(newLot: Lot = null) {
    if (!this.catalog) {
      if (this.isForecastCatalog) {
        this.catalogService.getAllForecastCatalogs(this.id).subscribe(res => {
          this.catalogs = res;
          const catalog = res.find(f => f.catalogId === this.catalogId);
          this.languageService.getAuctionClusterLanguages(catalog.auctionClusterId).subscribe(res => {
            this.clusterLanguages = res;
            this.initDataSource(catalog);
            this.getDataProcess(catalog, newLot);
          });
          this.title.set('AUCTION.FORECAST_CATALOG_MANAGEMENT');

          if (this.masterDetailsLotId) {
            this.title.add('AUCTION.MASTER_DETAILS.TITLE');
          }

          this.title.add(catalog.name);
          this.title.append('LOTS.LOT_EDITOR');
        });
      } else {
        this.catalogService.getAllSupplyCatalogs(this.id).subscribe(res => {
          this.catalogs = res;
          const catalog = res.find(f => f.catalogId === this.catalogId);
          this.languageService.getAuctionClusterLanguages(catalog.auctionClusterId).subscribe(res => {
            this.clusterLanguages = res;
            this.initDataSource(catalog);
            this.getDataProcess(catalog, newLot);
          });
          this.title.set('AUCTION.SUPPLY_CATALOG_MANAGEMENT');

          if (this.masterDetailsLotId) {
            this.title.add('AUCTION.MASTER_DETAILS.TITLE');
          }

          this.title.add(catalog.name);
          this.title.append('LOTS.LOT_EDITOR');
        });
      }
    } else {
      this.languageService.getAuctionClusterLanguages(this.catalog.auctionClusterId).subscribe(res => {
        this.clusterLanguages = res;
        this.getDataProcess(this.catalog, newLot);
      });
      if (this.isForecastCatalog) {
        this.title.set('AUCTION.FORECAST_CATALOG_MANAGEMENT');
      } else {
        this.title.set('AUCTION.SUPPLY_CATALOG_MANAGEMENT');
      }

      if (this.masterDetailsLotId) {
        this.title.add('AUCTION.MASTER_DETAILS.TITLE');
      }

      this.title.add(this.catalog.name);
      this.title.append('LOTS.LOT_EDITOR');
    }
  }

  // tslint:disable:no-magic-numbers
  getDataProcess(catalog: Catalog, newLot: Lot) {
    this.catalog = catalog;

    const sources: Array<any> = [];

    let getNewData = false;

    if (!this.firstLoad) {
      sources.push(this.dataService.getNew(this.catalog.auctionId, this.catalogId, this.masterDetailsLotId));
      getNewData = true;
    }

    if (this.firstLoad) {
      sources.push(this.dataService.getNew(this.catalog.auctionId, this.catalogId, this.masterDetailsLotId));
      sources.push(this.productService.getProduct(this.id, this.catalog.productId));
      sources.push(this.languageService.getLanguages());
      sources.push(this.lookupTableService.getLookupTables(this.id));
      sources.push(this.calculationService.getCalculations(this.id, this.translateService.currentLang));
      getNewData = true;
    }
    if (getNewData) {
      this.spinner.show();
    }
    else {
      if (newLot) {
        this.lot = newLot;
        this.parseLotProperties();
        setTimeout(() => {
          this.removeFocusableFromDropdowns();
          this.removeFocusableFromCheckbox();
          this.setFocus();
        }, TIMEOUT);
      }
    }

    forkJoin(...sources).subscribe(result => {
      if (this.firstLoad) {
        this.lot = <Lot>result[0];
        this.product = <Product>result[1];
        this.languages = <Array<Language>>result[2];
        this.lookupTables = <Array<LookupTable>>result[3];
        this.calculations = <Array<Calculation>>result[4];

        this.isMasterDetailsShowing = this.isForecastCatalog ? this.product.hasForecastMasterDetails : this.product.hasSupplyMasterDetails;

        if (this.clusterLanguages.length > 0) {
          this.defaultClusterLanguage = this.clusterLanguages.find(l => l.isDefault);

          if (!this.defaultClusterLanguage) {
            this.defaultClusterLanguage = this.languages.find(l => l.isDefault);
          }
        }

        if (this.product.enableDesktopScreenLayout && this.product.enableTouchScreenLayout) {
          this.isMultiEditorModeEnabled = true;
        } else {
          this.isMultiEditorModeEnabled = false;
        }

      } else if (result[0]) {
        this.lot = <Lot>result[0];
      } else {
        this.lot = newLot;
      }

      this.isVertical = this.isForecastCatalog ? this.product.supplyForecastEditorLayout === 2 : this.product.supplyEditorLayout === 2;
      this.layoutWidth = this.isForecastCatalog ? this.product.supplyForecastEditorLayoutWidth : this.product.supplyEditorLayoutWidth;

      this.enableTouchScreenLayout = (this.product.enableTouchScreenLayout && this.isTouchMode) || this.viewOnlyMode;
      if (this.enableTouchScreenLayout)
        this.isVertical = false;

      this.parseLotProperties();

      if (this.firstLoad) {
        this.parseMasterDataDropdown();
        this.setDropdownValues();
        this.parseSupplyProperties();
        //this.resetEdit(true);
      }

      this.getPresetCookieProperty();
      this.parseDatesBeforeSave();

      this.latestNewLot = JSON.parse(JSON.stringify(this.lot));
      this.latestResetLot = JSON.parse(JSON.stringify(this.lot));

      setTimeout(() => {
        this.removeFocusableFromDropdowns();
        this.removeFocusableFromCheckbox();
        this.setFocus();
      }, TIMEOUT);

      this.firstLoad = false;
      this.spinner.hide();

      if (!this.isTouchMode) {
        this.getMasterDetails();
      }
    },
      error => {
        this.errorService.show(error);
        this.spinner.hide();
      });

    if (sources.length === 0) {
      if (!this.isTouchMode) {
        this.getMasterDetails();
      }
    }

    if (this.route.snapshot.routeConfig.data && this.route.snapshot.routeConfig.data['isForecast']) {
      this.getAuctionClusterNotificationLastTimeSent(NotificationTypeEnum.PublishingForecastCatalog);
    } else {
      this.getAuctionClusterNotificationLastTimeSent(NotificationTypeEnum.PublishingSupplyCatalog);
    }
  }
  // tslint:enable:no-magic-numbers

  private initDataSource(catalog: Catalog) {
    if (!this.firstLoad)
      return;

    this.customDataSource = new CustomStore({
      key: 'lotId',
      load: (loadOptions) => {
        this.focusedProperty = null;
        this.focusedComponent = null;

        this.gridRefreshing = true;
        let language = this.clusterLanguages.find(_ => _.code === this.lang);
        let languageCode = 'en-GB';

        if (language) {
          languageCode = language.code;
        }

        return new Promise((resolve, reject) => {
          this.dataService.loadCatalogLotSearched(catalog.auctionId,
            catalog.catalogId, languageCode, loadOptions, this.masterDetailsLotId).subscribe(
              result => {
                this.lotItems = result.loadResult['data'];
                this.itemsToRemove = result.lotIdsForDeleting;

                this.isSendNotificationDisabled = !result.hasPublishedLots;
                let returnObject = null;
                if (!this.lotItems) {
                  returnObject = {
                    data: [],
                    totalCount: 0
                  };
                }
                else {
                  returnObject = {
                    data: result.loadResult['data'],
                    totalCount: result.loadResult['totalCount']
                  };
                }
                resolve(returnObject);
              }, error => {
                reject(error);
              });
        });
      }
    });
  }

  getMasterDetails() {
    this.dataService.getMasterDetails(this.id, this.catalog.catalogId, this.isForecastCatalog)
      .subscribe(res => {
        this.masterDetailsItems = res.rows;
        this.parseColumns(res.columnTitles);
        this.parseObject(res.rows);
        this.generateMasterTable(res);
      },
        error => {
          this.errorService.show(error);
        });
  }

  private parseObject(rows: Array<GenericLotRow>) {
    this.masterDetailsData = [];

    // Ignore parsing if there are no columns defined
    if (this.masterDetailsColumns.length === 0) {
      return;
    }


    rows.forEach(row => {
      const obj = new Object();
      this.masterDetailsColumns.forEach((column, i) => {
        if (column.propertyTypeId === ProductPropertyTypes.DATE) {
          if (row.values[i])
            obj['key' + i] = moment.utc(row.values[i]).local().toISOString();
        }
        else if (column.propertyTypeId === ProductPropertyTypes.TEXT
          || column.propertyTypeId === ProductPropertyTypes.BOOLEAN
          || column.propertyTypeId === ProductPropertyTypes.IMAGE
          || column.propertyTypeId === ProductPropertyTypes.MASTER_DATA_VALUE
          || isNaN(Number(row.values[i]))) {
          // Add line breaks for string value
          let objValue = this.getTranslation(row.values[i]);
          objValue = this.addLineBreaks(objValue, column.propertyTypeId);
          obj['key' + i] = objValue;
        } else {
          obj['key' + i] = row.values[i];
        }
      });
      obj['__item__'] = row;

      this.masterDetailsData.push(obj);
    });

    // Reset default page size
    this.masterDetailsCurrentPage = this.masterDetailsLastRecordedPage;
    this.setMasterDetailsPageSize(this.pageSizeValue);
  }

  setMasterDetailsPageSize(event: any) {
    this.masterDetailsPageSizeValue = event;
    this.masterDetailsPageSize = event;
  }

  private parseColumns(columns: Array<GenericLotColumn>) {
    const _columns = [];
    columns.forEach(column => {
      _columns.push({
        name: this.getTranslation(column.name),
        propertyTypeId: column.propertyTypeId,
        propertyTypeFormatId: column.propertyTypeFormatId
      });
    });


    const _objectKeys = [];
    _columns.forEach((column, i) => {
      _objectKeys.push('key' + i);
    });

    this.masterDetailsObjectKeys = _objectKeys;
    this.masterDetailsColumns = _columns;
  }

  editMasterDetailsRow(lotId: number, event: Event) {
    event.stopPropagation();

    const url = this.router.createUrlTree([], { relativeTo: this.route, queryParams: { lotId: lotId }, queryParamsHandling: 'merge' }).toString();
    this.location.replaceState(url);

    this.masterDetailsLotId = lotId;
    this.manualRefresh();
  }

  reloadGrid() {
    this.dataGridInstance.getDataSource().reload();
  }

  getPresetCookieProperty() {
    let deviceId = this.cookieService.get(Cookies.COMPUTER_COOKIE);
    let masterDataIdField = null;
    let deviceProductProperty = this.product.productProperties.find(p => p.systemProductPropertyType === SystemProductPropertyTypesEnum.Computer);
    if (!this.deviceMasterDataListRowId && deviceId != undefined && deviceProductProperty) {
      const parsed = parseInt(deviceId);
      if (!isNaN(parsed)) {

        const masterData = this.masterDatas
          .find(md => md.masterDataListId === deviceProductProperty.masterDataListId);

        if (masterData) {
          masterDataIdField = masterData.fields.find(f => f.fieldType === MasterDataFieldTypeEnum.Id);

          masterData.rows.forEach(r =>
            r.values.forEach(v => {
              if (v.masterDataListFieldId === masterDataIdField.masterDataListFieldId) {
                if (v.intValue === parsed) {
                  this.deviceMasterDataListRowId = r.masterDataListRowId;
                }
              }
            }));
        }
      }
    }

    if (deviceProductProperty) {
      this.lot.lotPropertyGroups.forEach(g => {
        g.lotPropertyGroupRows.forEach(r => {
          r.lotProperties.forEach(lotProp => {
            if (lotProp.productPropertyId === deviceProductProperty.productPropertyId) {
              lotProp.masterDataListRowId = this.deviceMasterDataListRowId;

              if (!this.lot.inEditMode)
                this.onPropertyChanged(lotProp);
            }
          });
        });
      });
    }
  }

  isDateInvalid(date: any, required: boolean, selector: string, lotForm: NgForm) {
    if (!required) {
      return false;
    }
    let input = null;
    if (date && date.dateInput && date.dateInput.nativeElement) {
      input = date.dateInput.nativeElement;
      const classList = input.classList;
      let touched = false;
      let dirty = false;
      let invalid = false;
      classList.forEach(c => {
        if (c === 'ng-touched') {
          touched = true;
        }
        if (c === 'ng-dirty') {
          dirty = true;
        }
        if (c === 'ng-invalid') {
          invalid = true;
        }
      });

      if (date.time) {
        if (moment.isMoment(date.time)) {
          if (date.time.isValid()) {
            invalid = false;
          }
        } else {
          if (moment(date.time).isValid()) {
            invalid = false;
          }
        }
      }

      let el = lotForm.controls[selector];
      if (el) {
        if (invalid) {
          el.setErrors({ 'incorrect': invalid, 'invalid': invalid });
        }
        else
          el.setErrors(null);

      }

      return /*(touched || dirty) &&*/ invalid;
    }
    return false;
  }

  parseMasterDataDropdown() {
    this.lot.lotPropertyGroups.forEach(g => {
      if (!g.isHidden) {
        g.lotPropertyGroupRows.forEach(r => {
          r.lotProperties.forEach(lotProp => {
            if (lotProp.propertyTypeId === ProductPropertyTypes.MASTER_DATA_VALUE) {
              this.masterDataDropdowns.push({ productPropertyId: lotProp.productPropertyId });
            }
          });
        });
      }
    });
  }

  setDropdownValues() {
    this.masterDataDropdowns.forEach((md, i) => {
      const newValues = [];
      // apply lookups
      const lookupTableId = this.isProductWithLookupTable(md.productPropertyId);

      if (lookupTableId > 0) {
        const lookup = this.lot.lookupTables.find(l => l.lookupTableId === lookupTableId);
        if (lookup && lookup.lookupTableType == 0) {
          lookup.lookupTableRows.forEach(row => {
            if (row.resultMasterDataListRowId) {
              newValues.push(row.resultMasterDataListRowId);
            }
            else {
              row.lookupTableValues.forEach(value => {
                newValues.push(value.masterDataListRowId);
              });
            }
          });
        }
      }

      // apply masks
      const mask = this.lot.maskResults[md.productPropertyId];
      if (mask) {
        newValues.length = 0;
        mask.forEach(m => newValues.push(m.masterDataListRowId));
      }

      this.filteredDropdownIds[md.productPropertyId] = newValues;
    });

    this.latestNewLot = JSON.parse(JSON.stringify(this.lot));
    this.resetEdit();
  }

  isProductWithLookupTable(productPropertyId: number) {
    for (let i = 0; i < this.product.productProperties.length; i += 1) {
      if (this.product.productProperties[i].productPropertyId === productPropertyId
        && this.product.productProperties[i].lookupTableId) {
        return this.product.productProperties[i].lookupTableId;
      }
    }

    return -1;
  }

  findLotProperty(productPropertyId: number) {
    let lp = null;
    this.lot.lotPropertyGroups.forEach(group => {
      if (!group.isHidden) {
        group.lotPropertyGroupRows.forEach(row => {
          row.lotProperties.forEach(lotProperty => {
            if (lotProperty.productPropertyId === productPropertyId) {
              lp = lotProperty;
            }
          });
        });
      }
    });

    return lp;
  }

  calculateNumberOfBoxes(lot: Lot, calculationId: number) {
    let numberOfBoxesProp = null;
    let amount = 0;
    let boxContent = 0;
    this.lot.lotPropertyGroups.forEach(g => {
      g.lotPropertyGroupRows.forEach(r => {
        r.lotProperties.forEach(lotProperty => {
          const productProperty = this.product.productProperties.find(f => f.productPropertyId === lotProperty.productPropertyId);
          if (productProperty.systemProductPropertyType === SystemProductPropertyTypesEnum.Amount) {
            amount = lotProperty.decimalValue;
          }
          else if (productProperty.systemProductPropertyType === SystemProductPropertyTypesEnum.BoxContent) {
            boxContent = lotProperty.decimalValue;
          }
          else if (productProperty.calculationId === calculationId) {
            numberOfBoxesProp = lotProperty;
          }
        });
      });
    });

    if (numberOfBoxesProp && boxContent > 0) {
      amount = amount - 5;
      if (amount < 0) {
        amount = 0;
      }
      numberOfBoxesProp.decimalValue = Math.ceil(amount / boxContent);

      if (numberOfBoxesProp.decimalValue === 0)
        numberOfBoxesProp.decimalValue = 1;
    }
  }

  getDependentFrontEndCalculations(productPropertyId: number) {
    let dependentPropIds = [];
    const productProperty = this.product.productProperties.find(f => f.productPropertyId === productPropertyId);
    if (!productProperty) {
      return dependentPropIds;
    }
    let calculations = this.calculations.filter(c => c.calculationType === CalculationFieldTypes.FRONTEND);
    for (let i = 0; i < calculations.length; i += 1) {
      for (let j = 0; j < calculations[i].calculationFields.length; j += 1) {
        if (calculations[i].calculationFields[j].productPropertyId === productPropertyId
          && dependentPropIds.indexOf(calculations[i]) === -1) {
          dependentPropIds = [...dependentPropIds, calculations[i]];
        }
      }
    }

    return dependentPropIds;
  }

  doFrontEndCalculations(productPropertyId: number) {
    const dependentFrontEndCalculations = this.getDependentFrontEndCalculations(productPropertyId);

    dependentFrontEndCalculations.forEach(c => {
      if (c.frontEndOperation === CalculationFrontEndTypes.NUMBER_OF_BOXES) {
        this.calculateNumberOfBoxes(this.lot, c.calculationId);
      }
    });
  }

  getTranslatableValue(lotProperty: LotProperty) {
    const tr = this.translatableFields.find(tf => tf.componentIndex === lotProperty.productPropertyId);
    if (tr) {
      return tr.data;
    }

    return null;
  }

  findPropertyValue(lotProperty: LotProperty, calculationField: CalculationsField = null, returnFullTranslatable = false): any {
    if (lotProperty.propertyTypeId === ProductPropertyTypes.DATE) {
      const dateFormatId = this.getpropertyTypeFormatId(this.product, lotProperty.productPropertyId);
      return this.dateTimeService.getDateStringByFormatAnyUtc(lotProperty.dateTimeValue, dateFormatId);
    } else if (lotProperty.propertyTypeId === ProductPropertyTypes.DECIMAL) {
      const decimalFormatId = this.getpropertyTypeFormatId(this.product, lotProperty.productPropertyId);
      return lotProperty.decimalValue != null ? this.format(lotProperty.decimalValue, lotProperty.propertyTypeId, decimalFormatId) : '';
    } else if (lotProperty.propertyTypeId === ProductPropertyTypes.NUMBER) {
      return lotProperty.intValue != null ? this.format(lotProperty.intValue, lotProperty.propertyTypeId) : 0;
    } else if (lotProperty.propertyTypeId === ProductPropertyTypes.TEXT) {
      if (lotProperty.translatable) {
        return this.getTranslation(lotProperty.stringValue);
      }
      else if (lotProperty.stringValue) {
        return lotProperty.stringValue;
      }
      return "";
    } else if (lotProperty.propertyTypeId === ProductPropertyTypes.BOOLEAN) {
      return lotProperty.booleanValue ? 'true' : 'false';
    } else if (lotProperty.propertyTypeId === ProductPropertyTypes.URL) {
      return lotProperty.urlValue;
    } else if (lotProperty.propertyTypeId === ProductPropertyTypes.MASTER_DATA_VALUE) {
      return this.getMasterDataValue(lotProperty, calculationField, returnFullTranslatable);
    }

    return '';
  }

  findPropertyNumberValue(lotProperty: LotProperty, calculationField: CalculationsField = null): any {
    if (lotProperty.propertyTypeId === ProductPropertyTypes.DECIMAL) {
      const decimalFormatId = this.getpropertyTypeFormatId(this.product, lotProperty.productPropertyId);
      return lotProperty.decimalValue != null ? this.format(lotProperty.decimalValue, lotProperty.propertyTypeId, decimalFormatId) : '';
    } else if (lotProperty.propertyTypeId === ProductPropertyTypes.NUMBER) {
      return lotProperty.intValue != null ? this.format(lotProperty.intValue, lotProperty.propertyTypeId) : 0;
    } else if (lotProperty.propertyTypeId === ProductPropertyTypes.BOOLEAN) {
      return lotProperty.booleanValue ? 1 : 0;
    } else if (lotProperty.propertyTypeId === ProductPropertyTypes.MASTER_DATA_VALUE) {
      return this.getMasterDataNumberValue(lotProperty, calculationField);
    }

    return 0;
  }

  findMasterDataValue(masterDataValue: MasterDataValue, masterDataListField: MasterDataListField, returnFullTranslatable = false): any {
    if (masterDataListField.propertyTypeId === ProductPropertyTypes.DATE) {
      return this.dateTimeService.getDateStringByFormatAnyUtc(masterDataValue.dateTimeValue, masterDataListField.propertyTypeFormatId);
    } else if (masterDataListField.propertyTypeId === ProductPropertyTypes.DECIMAL) {
      return masterDataValue.decimalValue;
    } else if (masterDataListField.propertyTypeId === ProductPropertyTypes.NUMBER) {
      return masterDataValue.intValue;
    } else if (masterDataListField.propertyTypeId === ProductPropertyTypes.TEXT) {
      if (returnFullTranslatable) {
        return masterDataValue.stringValue;
      }
      if (masterDataListField.translatable) {
        return this.getTranslation(masterDataValue.stringValue);
      }
      return masterDataValue.stringValue;
    } else if (masterDataListField.propertyTypeId === ProductPropertyTypes.IMAGE) {
      return `<img style="max-width: 50px; max-height: 50px;" src=${masterDataValue.imageValue} />`;
    } else if (masterDataListField.propertyTypeId === ProductPropertyTypes.BOOLEAN) {
      return masterDataValue.booleanValue ? 'true' : 'false';
    }
  }

  findMasterDataNumberValue(masterDataValue: MasterDataValue, masterDataListField: MasterDataListField): any {
    if (masterDataListField.propertyTypeId === ProductPropertyTypes.DECIMAL) {
      return masterDataValue.decimalValue != null ? masterDataValue.decimalValue : 0;
    } else if (masterDataListField.propertyTypeId === ProductPropertyTypes.NUMBER) {
      return masterDataValue.intValue != null ? masterDataValue.intValue : 0;
    } else if (masterDataListField.propertyTypeId === ProductPropertyTypes.BOOLEAN) {
      return masterDataValue.booleanValue ? 1 : 0;
    }
  }

  getMasterDataNumberValue(lotProperty: LotProperty, calculationField: CalculationsField) {
    const masterDataListRowId = lotProperty.masterDataListRowId;
    let value = masterDataListRowId ? masterDataListRowId.toString() : '';
    this.masterDatas.forEach(md => {
      md.rows.forEach(r => {
        if (r.masterDataListRowId === masterDataListRowId) {
          r.values.forEach(v => {
            if (calculationField != null) {
              if (calculationField.masterDataListFieldId === v.masterDataListFieldId) {
                value = this.findMasterDataNumberValue(v, md.fields.find(f => f.masterDataListFieldId === v.masterDataListFieldId));
              }
            }
          });
        }
      });
    });
    return value;
  }

  getMasterDataValue(lotProperty: LotProperty, calculationField: CalculationsField = null, returnFullTranslatable = false) {
    const masterDataListRowId = lotProperty.masterDataListRowId;
    let value = masterDataListRowId ? masterDataListRowId.toString() : '';
    this.masterDatas.forEach(md => {
      md.rows.forEach(r => {
        if (r.masterDataListRowId === masterDataListRowId) {
          r.values.forEach(v => {
            if (calculationField != null) {
              if (calculationField.masterDataListFieldId === v.masterDataListFieldId) {
                value = this.findMasterDataValue(v, md.fields.find(f => f.masterDataListFieldId === v.masterDataListFieldId), returnFullTranslatable);
              }
            } else if (this.isForecastCatalog) {
              this.product.supplyForecastDataEntryGridProperties.forEach(prop => {
                if (prop.masterDataListFieldId === v.masterDataListFieldId &&
                  (lotProperty.orderNumber == null || prop.orderNumber === lotProperty.orderNumber)) {
                  value = this.findMasterDataValue(v, md.fields.find(f => f.masterDataListFieldId === v.masterDataListFieldId));
                }
              });
            } else {
              this.product.supplyDataEntryGridProperties.forEach(prop => {
                if (prop.masterDataListFieldId === v.masterDataListFieldId &&
                  (lotProperty.orderNumber == null || prop.orderNumber === lotProperty.orderNumber)) {
                  value = this.findMasterDataValue(v, md.fields.find(f => f.masterDataListFieldId === v.masterDataListFieldId));
                }
              });
            }
          });
        }
      });
    });
    return value;
  }

  getMasterDataFieldName(masterDataListFieldId: number) {
    for (let i = 0; i < this.masterDatas.length; i += 1) {
      for (let j = 0; j < this.masterDatas[i].fields.length; j += 1) {
        if (this.masterDatas[i].fields[j].masterDataListFieldId === masterDataListFieldId) {
          return this.getTranslation(this.masterDatas[i].fields[j].name);
        }
      }
    }
  }

  exportToCsv() {
    let csvString = '';
    let delimiter = ';';
    const currentLanguageCode = this.translateService.currentLang;

    this.languageService.getLanguages().subscribe(l => {
      const currentLanguge = l.filter(x => x.code === currentLanguageCode)[0]
      if (currentLanguge !== undefined) {
        delimiter = currentLanguge.csvDelimiter;
      }
    });

    this.supplyProperties.forEach(c => {
      csvString += `"${c.productPropertyName}"${delimiter}`;
    });

    csvString += `
    `;

    this.items.forEach(i => {
      i.displayLotProperties.forEach(p => {
        csvString += `"${p.displayValue}"${delimiter}`;
      });
      csvString += `
      `;
    });

    const blob = new Blob([this.exportConstants.BLOB_HEADER + csvString], { type: this.exportConstants.BLOB_TYPE });
    this.exportUrl = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(blob));
    const title = this.titleService.text.getValue();
    this.exportFilename = `${title}.${this.exportConstants.FILE_EXTENSION_CSV}`;

    window.setTimeout(() => { // anchor requires time interval to refresh its state
      this.exportLink.nativeElement.click();
      csvString = '';
    });
  }

  parseSupplyProperties() {
    this.supplyProperties = [];
    let gridProperties = this.product.supplyDataEntryGridProperties;
    if (this.isForecastCatalog) {
      gridProperties = this.product.supplyForecastDataEntryGridProperties;
    }
    const productProperties = this.product.productProperties;
    gridProperties.forEach(property => {
      const prop = new GridProperty();
      const productProperty = this.product.productProperties.find(f => f.productPropertyId === property.productPropertyId);
      if (productProperty) {
        prop.productPropertyId = property.productPropertyId;
        let name = property.name ? this.getTranslation(property.name) : null;
        if (!name) {
          name = this.getTranslation(productProperty.name);
          const samePP = gridProperties.filter(gp => gp.productPropertyId === property.productPropertyId);
          if (productProperty.propertyTypeId === ProductPropertyTypes.MASTER_DATA_VALUE && samePP.length > 1) {
            const fieldName = this.getMasterDataFieldName(property.masterDataListFieldId);
            name = `${name} (${fieldName})`;
          }
        }
        prop.productPropertyName = name;
        prop.masterDataListFieldId = property.masterDataListFieldId;
        prop.masterDataListId = productProperty.masterDataListId;
        prop.propertyTypeId = productProperty.propertyTypeId;
        prop.propertyTypeFormatId = productProperty.propertyTypeFormatId;
        prop.orderNumber = property.orderNumber;
        prop.productPropertySelectionId = property.productPropertySelectionId;

        const pp = productProperties.find(p => p.productPropertyId === prop.productPropertyId);
        if (pp) {
          prop.translatable = pp.translatable;
        }

        this.supplyProperties.push(prop);
      }
    });
    this.supplyProperties.sort((a, b) => a.orderNumber - b.orderNumber);
    return this.generateTable(this.supplyProperties);
    //return this.parseItems();
  }

  /*parseItems() {
    this.items.forEach(item => {
      this.supplyProperties.forEach(sp => {
        const lotProp = item.lotProperties.find(p => p.productPropertyId === sp.productPropertyId);
        if (lotProp) {
          const newLotProp = JSON.parse(JSON.stringify(lotProp));
          newLotProp.orderNumber = sp.orderNumber;
          newLotProp.propertyTypeId = sp.propertyTypeId;
          newLotProp.translatable = sp.translatable;
          newLotProp.displayValue = this.findPropertyValue(newLotProp);

          if (!item.displayLotProperties) {
            item.displayLotProperties = [];
          }
          item.displayLotProperties.push(newLotProp);
        }
      });
      if (item.displayLotProperties !== undefined) {
        item.displayLotProperties.sort((a, b) => a.orderNumber - b.orderNumber);
      }
    });

    return this.generateTable(this.supplyProperties);
  }*/

  parseLotProperties() {
    this.masterDataErrors = [];
    let editorProperties = this.product.supplyDataEditorProperties;
    if (this.isForecastCatalog) {
      editorProperties = this.product.supplyForecastDataEditorProperties;
    }
    this.lot.lotPropertyGroups.forEach(group => {
      if (!group.isHidden) {
        group.lotPropertyGroupRows.forEach(row => {
          row.lotProperties.forEach(lotProperty => {
            const productProperty = this.product.productProperties.find(p => {
              return p.productPropertyId === lotProperty.productPropertyId;
            });

            if (productProperty) {
              lotProperty.name = productProperty.name;
              lotProperty.productPropertyId = productProperty.productPropertyId;
              lotProperty.propertyTypeId = productProperty.propertyTypeId;
              lotProperty.name = productProperty.name;
              lotProperty.minValue = (productProperty.minValue !== undefined && productProperty.minValue !== null) ? productProperty.minValue : undefined;
              lotProperty.maxValue = (productProperty.maxValue !== undefined && productProperty.maxValue !== null) ? productProperty.maxValue : undefined;
              lotProperty.maxLength = (productProperty.maxLength !== undefined && productProperty.maxLength !== null) ? productProperty.maxLength : undefined;
              lotProperty.translatable = productProperty.translatable;
              lotProperty.propertyTypeFormatId = productProperty.propertyTypeFormatId;
              lotProperty.manuallyDisabled = false;
              lotProperty.systemProductPropertyType = productProperty.systemProductPropertyType;
              lotProperty.masterDataListId = productProperty.masterDataListId;
            }

            if (lotProperty.propertyTypeId === ProductPropertyTypes.BOOLEAN && !lotProperty.booleanValue) {
              lotProperty.booleanValue = false;
            }
            if (lotProperty.propertyTypeId === ProductPropertyTypes.URL) {
              // Set source of thumbnail if it exists
              if (lotProperty.urlValue) {
                this.largeImageSrc = lotProperty.urlValue;
                lotProperty.displayUrlImageValue = lotProperty.urlValue + "-thumbnail";
                lotProperty.displayUrlTextValue = lotProperty.urlValue;
              } else {
                lotProperty.displayUrlTextValue = null;
                lotProperty.displayUrlImageValue = this.emptyPictureSource;
              }
            }

            if (lotProperty.propertyTypeId === ProductPropertyTypes.DATE) {
              if (!lotProperty.dateTimeValue &&
                (lotProperty.propertyTypeFormatId === DateTimeTypes.LONG_TIME
                  || lotProperty.propertyTypeFormatId === DateTimeTypes.SHORT_TIME)) {
                //var today = moment.utc().locale(this.lang).local();
                var today = moment.utc('1971-01-01').locale(this.lang);
                today = today.hour(0);
                today = today.minute(0);
                today = today.second(0);
                lotProperty.dateTimeValue = today;
              }
              else if (lotProperty.propertyTypeFormatId === DateTimeTypes.LONG_TIME
                || lotProperty.propertyTypeFormatId === DateTimeTypes.SHORT_TIME) {
                lotProperty.dateTimeValue = moment.utc(lotProperty.dateTimeValue).locale(this.lang);
              }
              else {
                if (lotProperty.dateTimeValue) {
                  let serverReturnedTime = moment.utc(lotProperty.dateTimeValue);
                  if (lotProperty.propertyTypeFormatId === DateTimeTypes.LONG_DATE ||
                    lotProperty.propertyTypeFormatId === DateTimeTypes.SHORT_DATE) {
                    // Reset time part (HH:mm:ss) to 12PM
                    serverReturnedTime = serverReturnedTime.hour(12);
                    serverReturnedTime = serverReturnedTime.minute(0);
                    serverReturnedTime = serverReturnedTime.second(0);
                  }
                  lotProperty.dateTimeValue = moment.utc(serverReturnedTime).locale(this.lang).local();
                }
              }
            }

            if (lotProperty.propertyTypeId === ProductPropertyTypes.TEXT && lotProperty.translatable) {
              // Create empty JSON object for translation fields
              const emptyTranslation = {};
              this.clusterLanguages.forEach(lang => {
                emptyTranslation[lang.code] = '';
              });

              lotProperty.stringValue = lotProperty.stringValue ? lotProperty.stringValue : JSON.stringify(emptyTranslation);
            }

            const editorProperty = editorProperties.find(p => {
              return p.productPropertyId === lotProperty.productPropertyId;
            });

            if (editorProperty) {
              lotProperty.width = editorProperty.width;
              lotProperty.productPropertyEditorSelectionDetails = editorProperty.productPropertyEditorSelectionDetails;
              if (editorProperty.name) {
                const nameTranslatable = JSON.parse(editorProperty.name);
                if (nameTranslatable) {
                  this.clusterLanguages.forEach(lang => {
                    if (nameTranslatable[lang.code]) {
                      lotProperty.name = editorProperty.name;
                    }
                  });
                }
              }

              if (lotProperty.productPropertyEditorSelectionDetails
                && lotProperty.productPropertyEditorSelectionDetails.allowMultipleValuesWithinFieldDependency
                === AllowMultilpleValuesWithingFieldDependency.NO_SET_READ_ONLY
                && this.getPropValue(lotProperty)) {
                lotProperty.readOnlyOnceSelected = true;
              }

              if (!group.name) {
                const productGroup = this.product.productPropertyGroups.find(g => {
                  return g.productPropertyGroupId === editorProperty.productPropertyGroupId;
                });

                if (productGroup) {
                  group.name = this.getTranslation(productGroup.name);
                  group.collapse = productGroup.collapse;
                }
              }

              if (editorProperty.productPropertyEditorSelectionDetails.useWeighingScale) {
                this.addWeighingScaleConnection(editorProperty.productPropertyEditorSelectionDetails.weighingScaleUrl, editorProperty.productPropertyEditorSelectionDetails.useWeighingScaleSimulator, editorProperty.productPropertyId);
              }
            }
          });
        });
      }
    });
  }

  addWeighingScaleConnection(weighingScaleUrl: string, useSimulator: boolean, productPropertyId: number) {
    let existingConnection = this.weighingScaleConnections.find(_ => _.url === weighingScaleUrl);
    if (existingConnection) {
      if (!existingConnection.productProperties.find(_ => _ === productPropertyId))
        existingConnection.productProperties.push(productPropertyId);

      return;
    }

    let newConnection = new WeighingScaleConnection();
    newConnection.url = weighingScaleUrl;
    newConnection.productProperties = [];
    newConnection.productProperties.push(productPropertyId);
    newConnection.useSimulator = useSimulator;
    newConnection.hubConnection = this.createHubConnection(weighingScaleUrl);

    this.weighingScaleConnections.push(newConnection);

    this.startConnection(newConnection.url);
  }

  startConnection(url: string) {
    let existingConnection = this.weighingScaleConnections.find(_ => _.url === url);

    if (existingConnection && !existingConnection.started) {
      existingConnection.hubConnection
        .start()
        .then(() => {
          if (existingConnection.useSimulator) {
            existingConnection.hubConnection.send('SendMessage', 'start-simulator');
          }

          else {
            existingConnection.hubConnection.send('SendMessage', 'start');
          }

          existingConnection.started = true;
          existingConnection.error = false;
          this.messageReceiveTimeoutHandler = setTimeout(() => existingConnection.error = true, 5000);
        })
        .catch(error => {
          console.log('Error while establishing SignalR connection ' + url);
          console.log('Reconnecting in 2 sec...');
          this.reconnectionTimeoutHandler = setTimeout(() => this.startConnection(url), 2000);
          existingConnection.started = false;
          existingConnection.error = true;
        });
    }
  }

  multiEdit(lot: Lot, event: Event) {
    event.stopPropagation();

    let editorSelection = this.product.supplyDataEditorProperties;
    let gridSelection = this.product.supplyDataEntryGridProperties;

    if (this.isForecastCatalog) {
      editorSelection = this.product.supplyForecastDataEditorProperties;
      gridSelection = this.product.supplyForecastDataEntryGridProperties;
    }

    this.multiEditComponent.open(this.catalog.auctionId,
      this.catalog.catalogId, this.clusterLanguages, this.product, this.masterDatas,
      lot, editorSelection, gridSelection, this.supplyProperties, this.translatableFields);
  }

  edit(lotId: number, event: Event) {
    event.stopPropagation();
    const lot = this.dataGridInstance.getDataSource().items().find(l => l.lotId === lotId);

    if (lot && (lot.alreadyServedInClock || lot.state === 1)) {
      return;
    }

    if (this.enableTouchScreenLayout) {
      if (this.isForecastCatalog) {
        this.router.navigate(['/auction/catalogs/' + this.id + '/supplyforecastlots/' + this.catalogId + '/editor'], { queryParams: { masterDetailsLotId: this.masterDetailsLotId, lotId: lotId } });
        return;
      }
      this.router.navigate(['/auction/catalogs/' + this.id + '/supplylots/' + this.catalogId + '/editor'], { queryParams: { masterDetailsLotId: this.masterDetailsLotId, lotId: lotId } });
      return;
    }

    this.spinner.show();
    this.dataService.getLot(this.catalog.auctionId, lotId).subscribe(result => {
      this.lot = result;

      this.parseLotProperties();

      this.updateAllNumberOfPropsProcessed = 0;
      this.updateAllAppliedToAll = false;

      this.lot.inEditMode = true;

      this.setDropdownValues();
      this.parseDatesBeforeSave();
      this.spinner.hide();

      setTimeout(() => {
        this.removeFocusableFromDropdowns();
        this.removeFocusableFromCheckbox();
        this.setFocus();
        this.validateForm();

      }, TIMEOUT);
    }, error => {
      this.spinner.hide();
    });
  }

  resetEdit(forceReset: boolean = false) {
    if (forceReset)
      this.lot = JSON.parse(JSON.stringify(this.latestResetLot));
    else
      this.lot = JSON.parse(JSON.stringify(this.latestNewLot));

    this.getPresetCookieProperty();

    if (this.lotForm)
      this.lotForm.reset();

    this.lot.lotProperties.forEach(lp => {
      if (lp.propertyTypeId === ProductPropertyTypes.URL && lp.propertyTypeFormatId === UrlTypes.IMAGE_URL) {
        lp.displayUrlImageValue = this.emptyPictureSource;
        lp.displayUrlTextValue = null;
      }
    });

    setTimeout(() => {
      this.validateForm();
    }, 0);

    // Force refocus previously focused property
    if (this.focusedProperty) {
      let propertyWithComponent = this.componentsWithProperties.find(_ => _.property.productPropertyId === this.focusedProperty.productPropertyId);
      if (propertyWithComponent) {
        this.focusedProperty = propertyWithComponent.property;
        this.focusedComponent = propertyWithComponent.component;

        if (this.focusedComponent instanceof ElementRef) {
          this.focusedComponent.nativeElement.focus();
        }
        else
          this.focusedComponent.instance.focus();

        // Autoselect any potential input field text
        let el = <any>document.activeElement;
        if (el && el.select)
          el.select();
      }
    }
  }

  publishLots() {
    this.publishLotComponent.modalTitle = this.translations.PUBLISH;
    this.publishLotComponent.open(this.catalog.auctionId, this.catalogId, this.isForecastCatalog);
  }

  sendNotification(event: Event) {
    let type = NotificationTypeEnum.PublishingSupplyCatalog;
    if (this.route.snapshot.routeConfig.data && this.route.snapshot.routeConfig.data['isForecast']) {
      type = NotificationTypeEnum.PublishingForecastCatalog;
    }

    this.spinner.show();
    this.notificationService.sendNotifications(this.catalog.auctionId, type, this.catalog.catalogId, null, null, new Date().getTimezoneOffset())
      .subscribe((result) => {
        //refresh last sent
        this.getAuctionClusterNotificationLastTimeSent(type);
        this.spinner.hide();
      },
        error => {
          this.errorService.show(error);
          this.spinner.hide();
        });
  }

  getAuctionClusterNotificationLastTimeSent(notificationType: NotificationTypeEnum) {
    this.notificationService.getAuctionClusterNotificationLastTimeSent(notificationType, this.catalog.auctionClusterId, this.catalog.catalogId)
      .subscribe((res: Date) => {
        if (res != null) {
          this.lastTimeSentNotification = moment.utc(res).local().format('DD.MM.YYYY. HH:mm:ss');
        }
        else {
          this.lastTimeSentNotification = '';
        }
      },
        error => {
          this.errorService.show(error);
          this.spinner.hide();
        });
  }

  isPublishDisabled() {
    if (this.dataGridInstance) {
      let dataSource = this.dataGridInstance.getDataSource();
      if (dataSource && dataSource.items().length > 0) {
        return false;
      }
    }
    return true;
  }

  hasLastTimeSentNotification() {
    var hasValue = this.lastTimeSentNotification != null && this.lastTimeSentNotification != undefined && this.lastTimeSentNotification != '';
    return hasValue;
  }

  delete(itemId: number, event: Event) {
    event.stopPropagation();
    this.showActionsMessage = false;

    this.dataService.checkLotActions(this.catalog.auctionId, itemId).subscribe(res => {
      if (res) {
        this.itemIdToDelete = itemId;
        this.showActionsMessage = true;
        this.confirmation.opened = true;
      } else {
        this.itemIdToDelete = itemId;
        this.showActionsMessage = false;
        this.confirmation.opened = true;
      }
    }, error => {
      this.itemIdToDelete = itemId;
      this.showActionsMessage = false;
      this.confirmation.opened = true;
    });
  }

  printSupply(itemId: number, event: Event) {
    event.stopPropagation();
    this.showActionsMessage = false;

    const ids = [];
    ids.push(itemId);

    this.dataService.printSupply(this.catalog.auctionClusterId, this.catalogId, ids, false)
      .subscribe(r => {
      }, error => {
        this.errorService.show(error);
      });
  }

  deleteSelected() {
    this.spinner.show();
    if (this.itemIdToDelete === this.masterDetailsLotId) {
      if (this.lotItems) {
        const nextItem = this.lotItems.find(i => i.lotId !== this.masterDetailsLotId);
        if (nextItem) {
          this.masterDetailsLotId = nextItem.lotId;
        } else {
          this.masterDetailsLotId = null;
        }
      } else {
        this.masterDetailsLotId = null;
      }

    }
    this.dataService.delete(this.catalog.auctionId, this.itemIdToDelete)
      .subscribe((lots: Array<Lot>) => {
        if (this.itemIdToDelete === this.lot.lotId) {
          this.firstLoad = true;
          this.manualRefresh(null, true);
        } else {
          this.manualRefresh();
        }
        this.spinner.hide();
      },
        error => {
          this.errorService.show(this.errorService.translations.DELETE_ERROR_MESSAGE);
          this.spinner.hide();
        });
  }
  removeFilteredLots = function () {

    this.spinner.show();

    let lotIds: Array<number> = [];

    this.itemsToRemove.forEach(element => {
      lotIds.push(element);

      if (element === this.masterDetailsLotId) {
        if (this.items) {
          const nextItem = this.items.find(i => i.lotId !== this.masterDetailsLotId);
          if (nextItem) {
            this.masterDetailsLotId = nextItem.lotId;
          } else {
            this.masterDetailsLotId = null;
          }
        } else {
          this.masterDetailsLotId = null;
        }
      }
    });

    this.dataService.multiDelete(this.catalog.auctionId, lotIds)
      .subscribe((lots: Array<Lot>) => {

        if (this.element === this.lot.lotId) {
          this.firstLoad = true;
          this.manualRefresh(null, true);
        } else {
          this.manualRefresh();
        }
        this.spinner.hide();
      },
        error => {
          this.errorService.show(this.errorService.translations.DELETE_ERROR_MESSAGE);
          this.spinner.hide();
        });

    this.dataGridInstance.clearFilter();
  }


  validateTranslatable() {
    this.translatableFields.forEach(item => {
      if (!item.valid) {
        this.translatableValid = false;
      } else {
        this.translatableValid = true;
      }
    });
  }

  getTabIndex(p: LotProperty) {
    const d = p.productPropertyEditorSelectionDetails;
    if (d.focusType === FocusTypeEnum.NEVER) {
      return -1;
    } else if (d.focusType === FocusTypeEnum.ALWAYS) {
      return 1;
    }

    if (d.isVisible && !d.isReadOnly
      //&& !p.intValue
      //&& !p.decimalValue
      //&& !p.stringValue
      //&& !p.dateTimeValue
      //&& !p.booleanValue
      //&& !p.masterDataListRowId
    ) {
      return 1;
    } else {
      return -1;
    }
  }


  getPropIdsForMasterDataFields(lookupTableFields: Array<LookupTableField>) {
    const ids = [];
    lookupTableFields.forEach(ltf => {
      if (ltf.isResult) {
        this.product.productProperties.forEach(pp => {
          if (pp.masterDataListId === ltf.masterDataListId) {
            ids.push(pp.productPropertyId);
          }
        });
      }
    });

    return ids;
  }

  getDependentCalculations(productPropertyId: number) {
    let dependentPropIds = [];
    const productProperty = this.product.productProperties.find(f => f.productPropertyId === productPropertyId);
    if (!productProperty) {
      return dependentPropIds;
    }
    let calculations = this.calculations.filter(c => c.calculationType != CalculationFieldTypes.FRONTEND);
    for (let i = 0; i < calculations.length; i += 1) {
      for (let j = 0; j < calculations[i].calculationFields.length; j += 1) {
        if (calculations[i].calculationFields[j].productPropertyId === productPropertyId
          && dependentPropIds.indexOf(calculations[i]) === -1) {
          dependentPropIds = [...dependentPropIds, calculations[i]];
        }
      }
    }
    return dependentPropIds;
  }

  getLookupDependentPropIds(productPropertyId: number) {
    let dependentPropIds = [];
    const productProperty = this.product.productProperties.find(f => f.productPropertyId === productPropertyId);
    if (!productProperty) {
      return dependentPropIds;
    }
    this.product.productProperties.forEach(pp => {
      if (pp.dateFilterProductPropertyId === productProperty.productPropertyId) {
        dependentPropIds = [...dependentPropIds, pp.productPropertyId];
      }
    });
    const productPropertyMasterData = productProperty.masterDataListId;
    if (!productPropertyMasterData) {
      return dependentPropIds;
    }
    for (let i = 0; i < this.lookupTables.length; i += 1) {
      if (this.lookupTables[i].lookupTableType === 1) {
        continue;
      }
      for (let j = 0; j < this.lookupTables[i].lookupTableFields.length; j += 1) {
        if (!this.lookupTables[i].lookupTableFields[j].isResult
          && this.lookupTables[i].lookupTableFields[j].masterDataListId === productPropertyMasterData) {
          const propIds = this.getPropIdsForMasterDataFields(this.lookupTables[i].lookupTableFields);
          dependentPropIds = [...dependentPropIds, ...propIds];
        }
      }
    }

    return dependentPropIds;
  }

  getMultiplier(propertyTypeFormatId: number) {
    switch (propertyTypeFormatId) {
      case DecimalTypes.DECIMAL_0digit:
        return 1;
      case DecimalTypes.DECIMAL_1digit:
        return 10;
      case DecimalTypes.DECIMAL_2digit:
        return 100;
      case DecimalTypes.DECIMAL_3digit:
        return 1000;
      case DecimalTypes.DECIMAL_4digit:
        return 10000;
      case DecimalTypes.DECIMAL_5digit:
        return 100000;
      default:
        return null;
    }
  }

  // calculateAdd(calculation: Calculation, lotProperty: LotProperty) {
  //   const valuesToAdd = [];
  //   calculation.calculationFields.forEach(f => {
  //     const lp = this.getLotPropertyByIdFromCurrentLot(f.productPropertyId);
  //     if (lp) {
  //       const value = this.findPropertyNumberValue(lp);
  //       if (value !== null) {
  //         valuesToAdd.push(value);
  //       }
  //     }
  //   });
  //   let result = 0;
  //   valuesToAdd.forEach(v => {
  //     result += v;
  //   });
  //   this.setLotPropertyValue(lotProperty, result);
  // }

  // calculateDivide(calculation: Calculation, lotProperty: LotProperty) {
  //   if (calculation.calculationFields.length === 2) {
  //     const lp1 = this.getLotPropertyByIdFromCurrentLot(calculation.calculationFields[0].productPropertyId);
  //     const lp2 = this.getLotPropertyByIdFromCurrentLot(calculation.calculationFields[1].productPropertyId);
  //     if (lp1 && lp2) {
  //       const value1 = this.findPropertyNumberValue(lp1);
  //       const value2 = this.findPropertyNumberValue(lp2);

  //       if (value1 !== null && value2 !== null && value2 !== 0) {
  //         let result = value1 / value2;

  //         var multiplier = this.getMultiplier(lotProperty.propertyTypeFormatId);
  //         if (multiplier != null) {
  //           if (calculation.mathOperation == CalculationMathTypes.DIVIDE_CEILING) {
  //             result = Math.ceil(result * multiplier) / multiplier;
  //           }
  //           else if (calculation.mathOperation == CalculationMathTypes.DIVIDE_FLOOR) {
  //             result = Math.floor(result * multiplier) / multiplier;
  //           }

  //           result = this.formatDecimal(lotProperty.productPropertyId, result);
  //           this.setLotPropertyValue(lotProperty, result);
  //         }
  //       }
  //     }
  //   }
  // }

  // calculateMultiply(calculation: Calculation, lotProperty: LotProperty) {
  //   const valuesToAdd = [];
  //   calculation.calculationFields.forEach(f => {
  //     const lp = this.getLotPropertyByIdFromCurrentLot(f.productPropertyId);
  //     if (lp) {
  //       const value = this.findPropertyNumberValue(lp);
  //       if (value !== null) {
  //         valuesToAdd.push(value);
  //       }
  //     }
  //   });
  //   let result = valuesToAdd.length > 0 ? 1 : 0;
  //   valuesToAdd.forEach(v => {
  //     result *= v;
  //   });
  //   this.setLotPropertyValue(lotProperty, result);
  // }

  // calculateSubstract(calculation: Calculation, lotProperty: LotProperty) {
  //   const valuesToAdd = [];
  //   calculation.calculationFields.forEach(f => {
  //     const lp = this.getLotPropertyByIdFromCurrentLot(f.productPropertyId);
  //     if (lp) {
  //       const value = this.findPropertyNumberValue(lp);
  //       if (value !== null) {
  //         valuesToAdd.push(value);
  //       }
  //     }
  //   });
  //   if (valuesToAdd.length > 0) {
  //     let result = valuesToAdd[0];
  //     for (let i = 1; i < valuesToAdd.length; i += 1) {
  //       result -= valuesToAdd[i];
  //     }
  //     this.setLotPropertyValue(lotProperty, result);
  //   }
  // }

  private isJsonString(str: string) {
    try {
      const o = JSON.parse(str);
      if (o && typeof o === 'object') {
        return true;
      }

    } catch (e) {
      //
    }
    return false;
  }

  getTranslatableTextValue(jsonString, language) {
    const json = JSON.parse(jsonString);

    if (json[language.code]) {
      return json[language.code];
    } else {
      return json[this.defaultClusterLanguage.code].toString();
    }
  }

  joinTranslatables(values, separator) {
    const languagesJson = {};

    this.languages.forEach(l => {
      const stringValues = [];
      values.forEach(v => {
        if (this.isJsonString(v)) {
          const value = this.getTranslatableTextValue(v, l);
          stringValues.push(value);
        } else {
          stringValues.push(v);
        }
      });

      languagesJson[l.code] = stringValues.join(separator);
    });

    return JSON.stringify(languagesJson);
  }

  getDependentProperties(productPropertyId) {
    let dependentPropIds = [];

    this.product.supplyDataEditorProperties.forEach(prop => {
      if (prop.productPropertyEditorSelectionDetails.dependencyProperties) {
        if (prop.productPropertyEditorSelectionDetails.dependencyProperties.includes(productPropertyId)) {
          dependentPropIds.push(prop.productPropertyId)
        }
      }

    });

    return dependentPropIds;
  }

  onPropertyKeyStroke(changedProperty: LotProperty) {
    // if not master data check if really changed
    if (changedProperty.propertyTypeId !== ProductPropertyTypes.MASTER_DATA_VALUE && !changedProperty.changed) {
      return;
    }

    //this.parseDatesBeforeSave();
    this.doFrontEndCalculations(changedProperty.productPropertyId);

  }

  markValueChanged(changedProperty: LotProperty) {
    changedProperty.valueChanged = true;
  }

  minimalPriceChange(changedProperty: LotProperty) {

    if (changedProperty.systemProductPropertyType === SystemProductPropertyTypesEnum.MinPriceStop4 || changedProperty.systemProductPropertyType === SystemProductPropertyTypesEnum.MinPriceStop3
      || changedProperty.systemProductPropertyType === SystemProductPropertyTypesEnum.MinPriceStop2 || changedProperty.systemProductPropertyType === SystemProductPropertyTypesEnum.MinPriceStop1) {
      return true;
    }

    return false;
  }

  onPropertyChanged(changedProperty: LotProperty, isApplyingToAll: boolean = false, ev: any = null) {
    if (!changedProperty.valueChanged) {
      return;
    }

    changedProperty.valueChanged = false;

    // if not master data check if really changed
    if (changedProperty.propertyTypeId !== ProductPropertyTypes.MASTER_DATA_VALUE && !changedProperty.changed) {
      return;
    }

    this.parseDatesBeforeSave();
    changedProperty.changed = false;
    this.doFrontEndCalculations(changedProperty.productPropertyId);
    const dependentLookupPropIds = this.getLookupDependentPropIds(changedProperty.productPropertyId);
    const dependentCalculations = this.getDependentCalculations(changedProperty.productPropertyId);
    const dependentProperties = this.getDependentProperties(changedProperty.productPropertyId);
    const dependentMinimalPriceProperties = this.minimalPriceChange(changedProperty);
    if (dependentLookupPropIds.length > 0 || dependentCalculations.length > 0 || dependentProperties.length > 0 || dependentMinimalPriceProperties) {

      if ((dependentLookupPropIds.length > 0 || dependentCalculations.length > 0 || dependentProperties.length > 0) &&
        (this.focusedProperty === changedProperty || changedProperty.productPropertyEditorSelectionDetails.useWeighingScale) &&
        !this.isFilling) {

        this.spinner.show();
        this.isFilling = true;
        this.dataService.fillProperties(this.catalog.auctionId, changedProperty.productPropertyId, this.masterDetailsLotId, this.lot).subscribe(result => {

          this.errorMessage = null;
          result.lotPropertyGroups.forEach(g => {
            g.lotPropertyGroupRows.forEach(r => {
              r.lotProperties.forEach(lotProperty => {
                const lotProp = this.findLotProperty(lotProperty.productPropertyId);

                if (lotProp) {
                  switch (lotProp.propertyTypeId) {
                    case ProductPropertyTypes.MASTER_DATA_VALUE:
                      this.setLotPropertyValue(lotProp, lotProperty.masterDataListRowId);
                      break;
                    case ProductPropertyTypes.NUMBER:
                      this.setLotPropertyValue(lotProp, lotProperty.intValue);
                      break;
                    case ProductPropertyTypes.DECIMAL:
                      this.setLotPropertyValue(lotProp, lotProperty.decimalValue);
                      break;
                    case ProductPropertyTypes.DATE:
                      this.setLotPropertyValue(lotProp, lotProperty.dateTimeValue);
                      break;
                    case ProductPropertyTypes.BOOLEAN:
                      this.setLotPropertyValue(lotProp, lotProperty.booleanValue);
                      break;
                    case ProductPropertyTypes.URL:
                      this.setLotPropertyValue(lotProp, lotProperty.urlValue);
                      if (lotProperty.urlValue) {
                        lotProp.displayUrlTextValue = lotProperty.urlValue;
                        lotProp.displayUrlImageValue = lotProperty.urlValue + '-thumbnail';
                      } else {
                        lotProp.displayUrlTextValue = null;
                        lotProp.displayUrlImageValue = this.emptyPictureSource;
                      }
                      break;
                    default:
                      this.setLotPropertyValue(lotProp, lotProperty.stringValue);
                      break;
                  }

                  if (lotProp.productPropertyEditorSelectionDetails
                    && lotProp.productPropertyEditorSelectionDetails.allowMultipleValuesWithinFieldDependency
                    === AllowMultilpleValuesWithingFieldDependency.NO_SET_READ_ONLY
                    && this.getPropValue(lotProp)) {
                    lotProp.readOnlyOnceSelected = true;
                  }
                }
              });
            });
          });

          this.doFrontEndCalculations(changedProperty.productPropertyId);

          if (result.errorCode !== 0) {

            if (result.errorCode === ValidationErrorCode.MIN_STOP_PRICE_HIGHER_THAN_PREVIOUS_STOP_PRICE) {
              this.errorService.show(this.errorService.translations.MIN_STOP_PRICE_HIGHER_THAN_PREVIOUS_STOP_PRICE);
            }
          }

          result.lookupTables.forEach(l => {
            var existingLookup = this.lot.lookupTables.find(lookup => lookup.lookupTableId === l.lookupTableId);
            if (existingLookup) {
              existingLookup.lookupTableRows = l.lookupTableRows;
            }
            else {
              this.lot.lookupTables.push(l);
            }
          });

          Object.keys(result.maskResults).forEach((key, index) => {
            // key: the name of the object key
            // index: the ordinal position of the key within the object
            this.lot.maskResults[key] = result.maskResults[key];
          });


          //this.lot.lookupTables = result.lookupTables;
          //this.lot.maskResults = result.maskResults;

          this.setDropdownValues();

          this.isFilling = false;

          if (this.savePending && this.lotForm.form.valid) {
            this.savePending = false;
            setTimeout(() => {
              this.onSubmit();
            }, SAVE_TIMEOUT);
          }

          //if the form is invalid after fetching data, cancel the save procedure
          if (!this.lotForm || !this.lotForm.form.valid) {
            this.savePending = false;
          }

          this.spinner.hide();

          // Autoselect any potential input field text
          let el = <any>document.activeElement;
          if (el && el.select)
            el.select();
        }, error => {
          this.errorService.show(error);

          this.isFilling = false;
          this.savePending = false;
          this.spinner.hide();
        });
      }

      // Force focusing next property on click event but ignore when value has been cleared
      if (ev && ev.event && ev.event.originalEvent.type === 'click' && !!ev.value)
        this.focusNextElement('down');

      // this.lot.lotPropertyGroups.forEach(g => {
      //   g.lotPropertyGroupRows.forEach(r => {
      //     r.lotProperties.forEach(lotProperty => {
      //       // this lotProperty is dependent on changedProperty
      //       if (lotProperty.productPropertyEditorSelectionDetails
      //           && lotProperty.productPropertyEditorSelectionDetails.dependencyProperties.indexOf(changedProperty.productPropertyId) > -1) {
      //         this.processPropertyDependencies(lotProperty, changedProperty);
      //       }
      //     });
      //   });
      // });
    }
  }

  clearLotProperty(lotProperty: LotProperty) {
    lotProperty.intValue = null;
    lotProperty.decimalValue = null;
    lotProperty.stringValue = null;
    lotProperty.dateTimeValue = null;
    lotProperty.masterDataListRowId = null;
    lotProperty.booleanValue = null;
    lotProperty.urlValue = null;
  }

  setLotPropertyValue(lotProperty: LotProperty, value: any) {
    let isSame = false;
    switch (lotProperty.propertyTypeId) {
      case ProductPropertyTypes.MASTER_DATA_VALUE:
        if (lotProperty.masterDataListRowId === value) {
          isSame = true;
        }
        lotProperty.masterDataListRowId = value;
        break;
      case ProductPropertyTypes.NUMBER:
        if (lotProperty.intValue === value) {
          isSame = true;
        }
        lotProperty.intValue = value;
        break;
      case ProductPropertyTypes.DECIMAL:
        if (lotProperty.decimalValue === value) {
          isSame = true;
        }
        lotProperty.decimalValue = value;
        break;
      case ProductPropertyTypes.TEXT:
        if (lotProperty.stringValue === value) {
          isSame = true;
        }
        lotProperty.stringValue = value;
        break;
      case ProductPropertyTypes.DATE:
        if (lotProperty.dateTimeValue === value) {
          isSame = true;
        }
        if (!value &&
          (lotProperty.propertyTypeFormatId === DateTimeTypes.LONG_TIME
            || lotProperty.propertyTypeFormatId === DateTimeTypes.SHORT_TIME)) {
          //var today = moment.utc().locale(this.lang).local();
          var today = moment.utc('1971-01-01').locale(this.lang);
          today = today.hour(0);
          today = today.minute(0);
          today = today.second(0);
          lotProperty.dateTimeValue = today;
          break;
        }
        else if (lotProperty.propertyTypeFormatId === DateTimeTypes.LONG_TIME
          || lotProperty.propertyTypeFormatId === DateTimeTypes.SHORT_TIME) {
          lotProperty.dateTimeValue = moment.utc(value).locale(this.lang);
          break;
        }

        // Stop processing if value isn't set
        if (!value)
          break;

        let serverReturnedTime = moment.utc(value);
        if (lotProperty.propertyTypeFormatId === DateTimeTypes.LONG_DATE
          || lotProperty.propertyTypeFormatId === DateTimeTypes.SHORT_DATE) {
          // Set time components (HH:mm:ss) to 12PM to avoid convertint to and from UTC
          serverReturnedTime = serverReturnedTime.hour(12);
          serverReturnedTime = serverReturnedTime.minute(0);
          serverReturnedTime = serverReturnedTime.second(0);
        }
        lotProperty.dateTimeValue = moment.utc(serverReturnedTime).locale(this.lang).local();
        // lotProperty.dateTimeValue = value;
        break;
      case ProductPropertyTypes.BOOLEAN:
        if (lotProperty.booleanValue === value) {
          isSame = true;
        }
        lotProperty.booleanValue = value;
        break;
      case ProductPropertyTypes.URL:
        if (lotProperty.urlValue === value) {
          isSame = true;
        }
        lotProperty.urlValue = value;
        break;
      default:
        break;
    }

    return isSame;
  }

  focusFirstInvalidElement(formGroup: FormGroup) {
    let component = this.propertyComponents.find(_ => (_.time && !_.time.isValid()) || (!_.time && !_.isValid));
    if (component) {
      if (component instanceof ElementRef) {
        component.nativeElement.focus();
        this.focusedProperty = null;
      }
      else
        component.instance.focus();

      this.focusedComponent = component;
    }

    //(<any>Object).values(formGroup.controls).some((control, index) => {
    //  if (!control.valid) {
    //    const el = document.getElementsByName(Object.keys(formGroup.controls)[index])[0];
    //    if (el) {
    //      (<HTMLElement>el).focus();
    //      return true;
    //    }
    //  }
    //});
  }

  markDateInputsTouched() {
    const inputs = Array.prototype.slice.call(document.querySelectorAll('.dt-input dx-date-box'));

    inputs.forEach(i => {
      i.classList.remove('ng-untouched');
      i.classList.add('ng-touched');
    });
  }

  markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });

    this.markDateInputsTouched();
  }

  setFocus() {
    let componentWithProperty = this.componentsWithProperties.find(_ =>
      _.property.productPropertyEditorSelectionDetails.focusType === FocusTypeEnum.ALWAYS &&
      !_.property.productPropertyEditorSelectionDetails.isReadOnly &&
      _.property.productPropertyEditorSelectionDetails.isVisible &&
      !_.property.readOnlyOnceSelected &&
      !_.property.manuallyDisabled);

    if (componentWithProperty) {
      if (componentWithProperty.component instanceof ElementRef) {
        componentWithProperty.component.nativeElement.focus();
        this.focusedProperty = null;
      }
      else
        componentWithProperty.component.instance.focus();

      this.focusedComponent = componentWithProperty.component;
    }
  }

  getPropValue(prop: LotProperty) {
    switch (prop.propertyTypeId) {
      case ProductPropertyTypes.NUMBER:
        return prop.intValue;
      case ProductPropertyTypes.TEXT:
        return prop.stringValue;
      case ProductPropertyTypes.DECIMAL:
        return prop.decimalValue;
      case ProductPropertyTypes.DATE:
        return this.dateTimeService.getDateStringByFormatAnyUtc(prop.dateTimeValue, prop.propertyTypeFormatId);
      case ProductPropertyTypes.BOOLEAN:
        return prop.booleanValue;
      case ProductPropertyTypes.URL:
        return prop.urlValue;
      default:
        return prop.masterDataListRowId;
    }
  }

  // getLotPropertyByIdFromCurrentLot(productPropertyId: number) {
  //   for (let g = 0; g < this.lot.lotPropertyGroups.length; g += 1) {
  //     if (!this.lot.lotPropertyGroups[g].isHidden) {
  //       for (let r = 0; r < this.lot.lotPropertyGroups[g].lotPropertyGroupRows.length; r += 1) {
  //         for (let l = 0; l < this.lot.lotPropertyGroups[g].lotPropertyGroupRows[r].lotProperties.length; l += 1) {
  //           const lotProp = this.lot.lotPropertyGroups[g].lotPropertyGroupRows[r].lotProperties[l];
  //           if (lotProp.productPropertyId === productPropertyId) {
  //             return lotProp;
  //           }
  //         }
  //       }
  //     }
  //   }

  //   return null;
  // }

  // compareOldAndNewLotProperties(oldLotProperties: Array<LotProperty>, newLotProperties: Array<LotProperty>) {
  //   let allSame = true;
  //   for (let i = 0; i < oldLotProperties.length; i += 1) {
  //     const newProp = newLotProperties.find(np => np.productPropertyId === oldLotProperties[i].productPropertyId);

  //     if (newProp) {
  //       allSame = allSame && this.compareOldAndNewLotProperty(oldLotProperties[i], newProp);
  //     } else {
  //       return false;
  //     }

  //   }

  //   return allSame;
  // }

  // compareOldAndNewLotProperty(oldLotProperty: LotProperty, newLotProperty: LotProperty) {
  //   switch (newLotProperty.propertyTypeId) {
  //     case ProductPropertyTypes.NUMBER:
  //       return newLotProperty.intValue === oldLotProperty.intValue;
  //     case ProductPropertyTypes.DECIMAL:
  //       return newLotProperty.decimalValue === oldLotProperty.decimalValue;
  //     case ProductPropertyTypes.TEXT:
  //       return newLotProperty.stringValue === oldLotProperty.stringValue;
  //     case ProductPropertyTypes.DATE:
  //       const oldDateString = this.dateTimeService.getDateStringByFormatAnyUtc(oldLotProperty.dateTimeValue, newLotProperty.propertyTypeFormatId);
  //       const newDateString = this.dateTimeService.getDateStringByFormatAnyUtc(newLotProperty.dateTimeValue, newLotProperty.propertyTypeFormatId);

  //       return newDateString === oldDateString;
  //     case ProductPropertyTypes.BOOLEAN:
  //       return newLotProperty.booleanValue === oldLotProperty.booleanValue;
  //     default:
  //       return newLotProperty.masterDataListRowId === oldLotProperty.masterDataListRowId;
  //   }
  // }

  datesValid() {
    for (let g = 0; g < this.lot.lotPropertyGroups.length; g += 1) {
      if (!this.lot.lotPropertyGroups[g].isHidden) {
        for (let r = 0; r < this.lot.lotPropertyGroups[g].lotPropertyGroupRows.length; r += 1) {
          for (let l = 0; l < this.lot.lotPropertyGroups[g].lotPropertyGroupRows[r].lotProperties.length; l += 1) {
            const lotProp = this.lot.lotPropertyGroups[g].lotPropertyGroupRows[r].lotProperties[l];
            if (lotProp.propertyTypeId === ProductPropertyTypes.DATE && lotProp.productPropertyEditorSelectionDetails.required) {
              if (!moment(lotProp.dateTimeValue).isValid()) {
                return false;
              }
            }
          }
        }
      }
    }
    return true;
  }

  async onSubmit() {

    if (this.isFilling) {
      this.savePending = true;
      return;
    }

    this.lot.catalogId = this.catalogId;
    this.validateForm();

    if (!this.lotForm.valid || !this.datesValid() || !this.isFormValid) {
      this.markFormGroupTouched(this.lotForm.form);
      this.focusFirstInvalidElement(this.lotForm.form);
      return;
    }

    this.addLot();
  }

  dateChanged(property: LotProperty, dateTime: Moment) {
    if (moment(property.dateTimeValue).isSame(dateTime)) {
      return;
    }
    if (moment(dateTime).isValid()) {
      property.dateTimeValue = moment(dateTime);
    } else {
      property.dateTimeValue = null;
    }

    property.changed = true;
    property.valueChanged = true;
    this.onPropertyChanged(property);
  }

  showGroupFilters() {
    this.groupFiltersShowing = true;
    this.groupFilter.open(this.catalog.auctionId, this.catalogId, this.clusterLanguages, this.product);
  }

  showMoveLots() {
    this.moveLotsShowing = true;
    const filteredCatalogs = this.catalogs.filter(c => c.productId === this.catalog.productId);
    this.moveLots.open(this.catalog.auctionId, this.catalogId, this.clusterLanguages, this.product, filteredCatalogs);
  }

  hideMoveLots(isMoved: boolean) {
    this.moveLotsShowing = false;
    if (isMoved) {
      this.manualRefresh();
    }
  }

  hideGroupFilter() {
    this.groupFiltersShowing = false;
  }

  parseDatesBeforeSave() {
    this.lot.lotPropertyGroups.forEach(group => {
      if (!group.isHidden) {
        group.lotPropertyGroupRows.forEach(row => {
          row.lotProperties.forEach(lotProperty => {
            if (lotProperty.propertyTypeId === ProductPropertyTypes.DATE && lotProperty.dateTimeValue) {
              if (lotProperty.propertyTypeFormatId === DateTimeTypes.LONG_TIME || lotProperty.propertyTypeFormatId === DateTimeTypes.SHORT_TIME) {
                var value = moment.utc(lotProperty.dateTimeValue).locale(this.lang);
                value.year(1971).month(0).date(1);
                lotProperty.dateTimeValue = value;
              }
              let dateTimeValue = moment(lotProperty.dateTimeValue);

              if (lotProperty.propertyTypeFormatId === DateTimeTypes.LONG_DATE ||
                lotProperty.propertyTypeFormatId === DateTimeTypes.SHORT_DATE) {
                // Reset time part (HH:mm:ss) to 12PM to avoid issues with converting to UTC on server side
                dateTimeValue = dateTimeValue.hour(12);
                dateTimeValue = dateTimeValue.minute(0);
                dateTimeValue = dateTimeValue.second(0);
              }

              lotProperty.dateTimeValue = dateTimeValue;
            }

            if (lotProperty.propertyTypeId === ProductPropertyTypes.TEXT && lotProperty.translatable) {
              const field = this.translatableFields.find(tf => tf.componentIndex === lotProperty.productPropertyId);
              if (field) {
                lotProperty.stringValue = field.data;
              }
            }
          });
        });
      }
    });
  }

  addLot(): boolean {
    if (!this.lotAddingInProgress) {
      if (this.lot.lotPropertyGroups.length === 0) {
        return;
      }
      this.lotAddingInProgress = true;
      this.spinner.show();

      this.parseDatesBeforeSave();

      if (this.lot.inEditMode && !this.addNew) {
        this.dataService.edit(this.catalog.auctionId, this.lot).subscribe(res => {
          this.lotAddingInProgress = false;
          this.spinner.hide();
          this.manualRefresh(null, false, res);
          this.lotForm.reset();
          this.lot.lotProperties.forEach(lp => {
            if (lp.propertyTypeId === ProductPropertyTypes.URL && lp.propertyTypeFormatId === UrlTypes.IMAGE_URL) {
              lp.displayUrlImageValue = this.emptyPictureSource;
              lp.displayUrlTextValue = null;
            }
          });
          this.updateAllNumberOfPropsProcessed = 0;
          this.updateAllAppliedToAll = false;

          setTimeout(() => {
            this.validateForm();
          }, 200);

        }, error => {
          this.errorService.show(error);
          this.lotAddingInProgress = false;
          this.spinner.hide();
        });
        return true;
      } else {
        this.lot.lotId = 0;
        this.dataService.save(this.catalog.auctionId, this.lot).subscribe(res => {
          this.lotAddingInProgress = false;
          this.spinner.hide();
          this.masterDetailsLotId = res.lotId;
          this.manualRefresh(null, false, res);
          this.lotForm.reset();
          this.lot.lotProperties.forEach(lp => {
            if (lp.propertyTypeId === ProductPropertyTypes.URL && lp.propertyTypeFormatId === UrlTypes.IMAGE_URL) {
              lp.displayUrlImageValue = this.emptyPictureSource;
              lp.displayUrlTextValue = null;
            }
          });
          this.updateAllNumberOfPropsProcessed = 0;
          this.updateAllAppliedToAll = false;

          setTimeout(() => {
            this.validateForm();
          }, 200);

        }, error => {
          this.errorService.show(error);
          this.lotAddingInProgress = false;
          this.spinner.hide();
        });
        return true;
      }
    }
  }

  getTranslation(value: string) {
    return this.languageService.getTranslatableText(value);
  }

  focusNextElement(direction: 'up' | 'down' = 'down', ignoreConfig: boolean = false) {
    let components = this.propertyComponents.toArray();
    let index = 0;

    if (this.focusedProperty)
      index = this.componentsWithProperties.findIndex(_ => _.property.productPropertyId === this.focusedProperty.productPropertyId);

    if (direction === 'down') {
      if (index === components.length - 1)
        index = -1;

      for (var i = index + 1; i < components.length; i++) {
        let component = components[i];
        let property: LotProperty = null;
        let componentWithPropery = this.componentsWithProperties.find(_ => _.component === component);
        if (componentWithPropery)
          property = componentWithPropery.property;

        if (component) {
          if (ignoreConfig) {
            if (property) {
              if (!(property.productPropertyEditorSelectionDetails.isReadOnly || property.readOnlyOnceSelected || property.manuallyDisabled) &&
                property.productPropertyEditorSelectionDetails.isVisible) {
                if (component instanceof ElementRef) {
                  component.nativeElement.focus();
                  this.focusedProperty = null;
                }
                else
                  component.instance.focus();

                this.focusedComponent = component;
                this.focusedProperty = property;
                break;
              }
            }
            else {
              if (component instanceof ElementRef) {
                component.nativeElement.focus();
                this.focusedProperty = null;
              }
              else
                component.instance.focus();

              this.focusedComponent = component;
              break;
            }
          }
          else {
            if (property) {
              let tabIndex = this.getTabIndex(property);
              if (!(property.productPropertyEditorSelectionDetails.isReadOnly || property.readOnlyOnceSelected || property.manuallyDisabled) &&
                property.productPropertyEditorSelectionDetails.isVisible &&
                tabIndex && tabIndex !== -1) {
                if (component instanceof ElementRef) {
                  component.nativeElement.focus();
                  this.focusedProperty = null;
                }
                else
                  component.instance.focus();

                this.focusedComponent = component;
                this.focusedProperty = property;
                break;
              }
            }
            else {
              if (component instanceof ElementRef) {
                component.nativeElement.focus();
                this.focusedProperty = null;
              }
              else
                component.instance.focus();

              this.focusedComponent = component;
              break;
            }
          }
        }
      }
    }
    else {
      if (index <= 0)
        index = components.length;

      for (var i = index - 1; i >= 0; i--) {
        let component = components[i];
        let property: LotProperty = null;
        let componentWithPropery = this.componentsWithProperties.find(_ => _.component === component);
        if (componentWithPropery)
          property = componentWithPropery.property;

        if (component) {
          if (ignoreConfig) {
            if (property) {
              if (!(property.productPropertyEditorSelectionDetails.isReadOnly || property.readOnlyOnceSelected || property.manuallyDisabled) &&
                property.productPropertyEditorSelectionDetails.isVisible) {
                if (component instanceof ElementRef) {
                  component.nativeElement.focus();
                  this.focusedProperty = null;
                }
                else
                  component.instance.focus();

                this.focusedComponent = component;
                this.focusedProperty = property;
                break;
              }
            }
            else {
              if (component instanceof ElementRef) {
                component.nativeElement.focus();
                this.focusedComponent = component;
                this.focusedProperty = null;
              }
              else
                component.instance.focus();

              break;
            }
          }
          else {
            if (property) {
              let tabIndex = this.getTabIndex(property);
              if (!(property.productPropertyEditorSelectionDetails.isReadOnly || property.readOnlyOnceSelected || property.manuallyDisabled) &&
                property.productPropertyEditorSelectionDetails.isVisible &&
                tabIndex && tabIndex !== -1) {
                if (component instanceof ElementRef) {
                  component.nativeElement.focus();
                  this.focusedProperty = null;
                }
                else
                  component.instance.focus();

                this.focusedComponent = component;
                this.focusedProperty = property;
                break;
              }
            }
            else {
              if (component instanceof ElementRef) {
                component.nativeElement.focus();
                this.focusedProperty = null;
              }
              else
                component.instance.focus();

              this.focusedComponent = component;
              break;
            }
          }
        }
      }
    }
  }

  initializeComponent(component, property) {
    let componentWithProperty = this.componentsWithProperties.find(_ => _.property.productPropertyId === property.productPropertyId);

    if (!componentWithProperty)
      this.componentsWithProperties.push({ component: component, property: property });
    else {
      componentWithProperty.component = component;
      componentWithProperty.property = property;
    }
  }

  focusProperty(component: any, property: LotProperty, e: any = null) {
    this.focusedComponent = component;
    this.focusedProperty = property;

    // Autoselect any potential input field text
    let el = <any>document.activeElement;
    if (el && el.select)
      el.select();
  }

  //@HostListener('window:keydown', ['$event'])
  changeFocus(event: any) { // tslint:disable-line:no-unused-variable
    if (event.keyCode === ENTER_KEY) {
      this.focusNextElement();
      if (!event.target.classList.contains('btn-add')) {
        event.preventDefault();
      }
    }
    else if (event.keyCode === TAB_KEY) {
      event.preventDefault();
      this.focusNextElement(event.shiftKey ? 'up' : 'down', true);
    }
    else if (event.keyCode === SAVE_KEY && event.ctrlKey) {
      event.preventDefault();
      if (this.savePending ||
        this.lotAddingInProgress ||
        !this.lotForm.form.valid ||
        !this.catalog || !this.catalog.enableCreate) {
        return;
      }

      if (this.lot.inEditMode) {
        this.addNew = false;
      }
      else {
        this.addNew = true;
      }
      this.focusNextElement();
      this.onSubmit();

    }
  }

  // private getFocusableFields() {
  //   this.fields = document.getElementsByClassName('focusable');
  // }

  // elementFocus(elementIndex: number) {
  //   this.fieldIndex = elementIndex;
  // }

  elementBlur(lotProperty: LotProperty, element: NgForm, e?: any) {
    // this.enforceRuleSet();
    // this.validateInputs();
    this.validate(lotProperty, element, e);

    // Force close dropdown on blur
    if (e && e.component && e.component.close)
      e.component.close();
  }

  createDataSchema() {
    const schema = new ImportSchema();

    this.schema = schema;
  }

  // tslint:disable:no-magic-numbers
  validate(prop: LotProperty, element: NgForm, e?: any) {
    let numericValue: number;
    let textValue: string;
    switch (prop.propertyTypeId) {
      case 1: {
        numericValue = prop.intValue;
        break;
      }
      case 2: {
        let multiplier = this.getMultiplier(prop.propertyTypeFormatId);

        if (multiplier != null) {
          prop.decimalValue = Math.round(prop.decimalValue * multiplier) / multiplier;
        }
        numericValue = prop.decimalValue;
        break;
      }
      case 3: {
        textValue = prop.stringValue;
        break;
      }
      default: {
        break;
      }
    }
    // tslint:enable:no-magic-numbers
  }

  getDecimalStep(prop: LotProperty) {
    switch (prop.propertyTypeFormatId) {
      case DecimalTypes.DECIMAL_0digit:
        return "1";
      case DecimalTypes.DECIMAL_1digit:
        return "0.1";
      case DecimalTypes.DECIMAL_2digit:
        return "0.01";
      case DecimalTypes.DECIMAL_3digit:
        return "0.001";
      case DecimalTypes.DECIMAL_4digit:
        return "0.0001";
      case DecimalTypes.DECIMAL_5digit:
        return "0.00001";
      default:
        return "1";
    }
  }

  getDecimalFormat(prop: LotProperty) {
    switch (prop.propertyTypeFormatId) {
      case DecimalTypes.DECIMAL_0digit:
        return "#0";
      case DecimalTypes.DECIMAL_1digit:
        return "#0.#";
      case DecimalTypes.DECIMAL_2digit:
        return "#0.##";
      case DecimalTypes.DECIMAL_3digit:
        return "#0.###";
      case DecimalTypes.DECIMAL_4digit:
        return "#0.####";
      case DecimalTypes.DECIMAL_5digit:
        return "#0.#####";
      default:
        return "#0";
    }
  }

  /*const elementName = element.name;
  if (!isNaN(numericValue)) {
    if ((prop.minValue != null && numericValue < prop.minValue) || (prop.maxValue != null && numericValue > prop.maxValue)) {
      this.lotForm.controls[elementName].setErrors({ 'valid': false });
    }
  }
  else if (isNaN(numericValue) && prop.propertyTypeId === 2) {
    if (prop.productPropertyEditorSelectionDetails.required) {
      this.lotForm.controls[elementName].setErrors({ 'valid': false });
    }
  }
  else if (textValue) {
    if (prop.maxLength && textValue.length > prop.maxLength) {
      this.lotForm.controls[elementName].setErrors({ 'valid': false });
    }
  }*/

  initNumericValues(prop: LotProperty) {
    switch (prop.propertyTypeId) {
      case 1: {
        if (!prop.intValue && prop.productPropertyEditorSelectionDetails.required) {
          prop.intValue = prop.minValue ? prop.minValue : 0;
        }
        break;
      }
      case 2: {
        let multiplier = this.getMultiplier(prop.propertyTypeFormatId);

        if (multiplier != null) {
          prop.decimalValue = Math.round(prop.decimalValue * multiplier) / multiplier;
        }

        if (!prop.decimalValue && prop.productPropertyEditorSelectionDetails.required) {
          prop.decimalValue = prop.minValue ? prop.minValue : 0;
        }
        break;
      }
      default: {
        break;
      }
    }
  }

  startsWithSearchFn(term: string, item: any) {
    let label = item["label"].toLocaleLowerCase();
    return label.toLocaleLowerCase().indexOf(term.toLocaleLowerCase()) == 0;
  }

  opetLotEditor(event: Event) {
    event.stopPropagation();
    if (this.isForecastCatalog) {
      this.router.navigate(['/auction/catalogs/' + this.id + '/supplyforecastlots/' + this.catalogId + '/editor'], { queryParams: { masterDetailsLotId: this.masterDetailsLotId } });
    } else {
      this.router.navigate(['/auction/catalogs/' + this.id + '/supplylots/' + this.catalogId + '/editor'], { queryParams: { masterDetailsLotId: this.masterDetailsLotId } });
    }

  }

  isGroupHiddenOrEmpty(group: LotPropertyGroup) {
    if (group.isHidden) {
      return true;
    }

    let isEmpty = !group.lotPropertyGroupRows.some(r =>
      !r.lotProperties.some(p =>
        p.productPropertyEditorSelectionDetails && !p.productPropertyEditorSelectionDetails.isVisible));

    return isEmpty;
  }

  initColumnMaster = (e: any) => {
    this.dataGridMasterInstance = e.component;
  }

  initColumn = (e: any) => {
    this.dataGridInstance = e.component;
    this.refresh(e);
  }

  generateMasterTable = (rows: any) => {
    if (rows.length === 0) {
      return;
    }
    let index = 2;
    let gridInstance = this.dataGridInstance;
    const columns = [];
    this.gridMasterColumns = [];
    this.gridColumns = [];

    index = 1;
    gridInstance = this.dataGridMasterInstance;


    const gridCurrentColums = gridInstance.getVisibleColumns();

    rows.columnTitles.forEach((row: any, i: number) => {
      let columnName = this.getTranslation(row.name);
      if (columns.includes(columnName)) {
        let j = 0;
        do {
          columnName = this.getTranslation(row.name) + j;
          j++;
        } while (columns.includes(columnName));
      }
      columns.push(columnName);
      const isColumn = gridCurrentColums.find(c => c.dataField === columnName);

      if (!isColumn) {
        gridInstance.addColumn({
          dataField: columnName,
          caption: this.getTranslation(row.name),
          visibleIndex: i + index,
          allowEditing: false,
          dataType: this.getDataType(row.propertyTypeId, row.propertyTypeFormatId),
          encodeHtml: false,
          format: this.getformatDevX(row.propertyTypeId, row.propertyTypeFormatId)
          //precision: this.getPrecision(row.propertyTypeId, row.propertyTypeFormatId),
          //lang: this.translateService.currentLang
        });
      }
    });

    columns.push('isPublished');
    columns.push('lotId');

    this.gridMasterColumns = [...columns];


    this.generateMasterTableData();
  }

  generateTable = (gridProperties: any) => {
    if (gridProperties.length === 0) {
      return;
    }
    let index = 3;
    let gridInstance = this.dataGridInstance;
    const columns = [];
    this.gridMasterColumns = [];
    this.gridColumns = [];
    let allGridColumns;

    /*const colNames = gridProperties.map(p => {
      return {
        'name': p.productPropertyName,
        'propertyTypeId': p.propertyTypeId,
        'propertyTypeFormatId': p.propertyTypeFormatId
      };
    });*/
    const colNames = [];
    gridProperties.forEach(p => {
      var columnName = {
        'name': p.productPropertyName,
        'propertyTypeId': p.propertyTypeId,
        'propertyTypeFormatId': p.propertyTypeFormatId,
        'suffix': ''
      };

      if (gridProperties.filter(pp => pp.productPropertyId === p.productPropertyId && pp.productPropertyName === p.productPropertyName).length > 1) {
        var masterDataList = this.masterDatas.find(m => m.masterDataListId == p.masterDataListId);
        var field = masterDataList.fields.find(f => f.masterDataListFieldId === p.masterDataListFieldId);
        columnName.suffix = ' (' + this.getTranslation(field.name) + ')'
      }
      colNames.push(columnName);
    });
    gridProperties = {
      'columnTitles': colNames
    };

    if (this.dataGridInstance.option().stateStoring) {
      allGridColumns = <any>localStorage.getItem(this.dataGridInstance.option().stateStoring.storageKey);
      if (allGridColumns) {
        allGridColumns = JSON.parse(allGridColumns);

        let storegeColumnNames = allGridColumns.columns.map(_ => _.dataField);
        let gridColumnNames = gridProperties.columnTitles.map(_ => _.name);
        let columnNamesDiff = storegeColumnNames.map(_ => gridColumnNames.indexOf(_) !== -1 ? null : _).filter(_ => _ !== null);
        columnNamesDiff.forEach(element => {
          if (element !== 'lotId' && element !== '__buttons' && element !== 'isPublished') {
            this.dataGridInstance.deleteColumn(element);
          }
        });
      }
    }

    gridProperties.columnTitles.forEach((column: any, i: number) => {
      let columnName = this.getTranslation(column.name);
      if (columns.includes(columnName)) {
        let j = 0;
        do {
          columnName = this.getTranslation(column.name) + j;
          j++;
        } while (columns.includes(columnName));
      }
      columns.push(columnName);
      let isColumn = null;

      if (allGridColumns) {
        isColumn = allGridColumns.columns.find(_ => _.dataField === columnName)
      }

      if (!isColumn) {
        gridInstance.addColumn({
          dataField: this.getTranslation(column.name) + column.suffix,
          caption: this.getTranslation(column.name) + column.suffix,
          visibleIndex: i + index,
          allowEditing: false,
          //dataType: this.getDataType(column.propertyTypeId),
          encodeHtml: false,
          format: this.getformatDevX(column.propertyTypeId, column.propertyTypeFormatId)
          //precision: this.getPrecision(column.propertyTypeId, column.propertyTypeFormatId)
        });
      }
    });

    columns.push('isPublished');
    columns.push('lotId');

    this.gridColumns = [...columns];

    //this.reloadGrid();

    if (this.route.snapshot.queryParams['lotId']) {
      if (!this.viewOnlyMode) {
        this.edit(+this.route.snapshot.queryParams['lotId'], new Event('click'));
      }
    }
  }

  getformatDevX(propertyTypeId: number, propertyTypeFormatId: number) {
    if (propertyTypeId == ProductPropertyTypes.DECIMAL) {
      let decimalPoints = this.getPrecision(propertyTypeId, propertyTypeFormatId);

      return {
        precision: decimalPoints,
        type: 'decimal',
        formatter: (value) => {
          let numberFormatter = new Intl.NumberFormat(this.lang, { useGrouping: true, minimumFractionDigits: decimalPoints, maximumFractionDigits: decimalPoints });
          return numberFormatter.format(value);
        },
        parser: (value) => {
          return parseFloat(value);
        }
      };
    }

    return undefined;
  }

  getPrecision(propertyTypeId: number, propertyTypeFormatId: number) {
    if (propertyTypeId == ProductPropertyTypes.DECIMAL) {
      if (propertyTypeFormatId == DecimalTypes.DECIMAL_0digit)
        return 0;
      else if (propertyTypeFormatId == DecimalTypes.DECIMAL_1digit)
        return 1;
      if (propertyTypeFormatId == DecimalTypes.DECIMAL_2digit)
        return 2;
      if (propertyTypeFormatId == DecimalTypes.DECIMAL_3digit)
        return 3;
      if (propertyTypeFormatId == DecimalTypes.DECIMAL_4digit)
        return 4;
      if (propertyTypeFormatId == DecimalTypes.DECIMAL_5digit)
        return 5;
    }

    return undefined;
  }

  generateMasterTableData = () => {
    this.gridItems = [];
    this.gridMasterItems = [];
    let data = [];
    let columns = this.gridColumns;

    data = this.masterDetailsData;
    columns = this.gridMasterColumns;

    data.forEach((row, i) => {
      let gridItem = {};
      columns.forEach((column, j) => {
        if (column !== 'isPublished' && column !== 'lotId') {
          let d = this.getTranslation(row['key' + j]);
          if (typeof d === 'string') {
            if (d.indexOf('<') !== -1) {
              d = this.stripHTML(d);
            }
          }
          gridItem[column] = d;
        } else {
          if (column === 'isPublished') {
            gridItem[column] = row.__item__.isPublished;
          }
          else if (column === 'lotId') {
            gridItem[column] = row.__item__.lotId;
          }
        }

        gridItem['__item__'] = row.__item__;
      });

      this.gridMasterItems.push(gridItem);
    });

    this.dataGridMasterInstance.option('dataSource', this.gridMasterItems);
  }

  rowClickMaster = (e: any) => {
    this.editMasterDetailsRow(+e.data.__item__.id, new Event('click'));
    this.refresh(new Event('click'));
  }

  editItem = (e: any) => {
    this.addNew
    this.edit(+e.row.data.lotId, new Event('click'));
  }

  rowClick = (e: any) => {
    if (!this.viewOnlyMode) {
      this.edit(+e.data.lotId, new Event('click'));
    }
  }

  printItem = (e: any) => {
    this.printSupply(+e.row.data.lotId, new Event('click'));
  }

  deleteItem = (e: any) => {
    this.itemIdToDelete = +e.row.data.lotId;
    this.confirmation.opened = true;
  }

  multiEditItem = (e: any) => {
    let lotId = +e.row.data.lotId;
    let lotItem = this.lotItems.find(_ => _.lotId === lotId);

    this.multiEdit(lotItem, new Event('click'));
  }

  viewGridIcons = (e: any) => {

    let alreadyServed = e.row.data.alreadyServedInClock;
    let state = e.row.data.state;


    if (!alreadyServed && state !== 1
      && this.catalog && !this.catalog.viewOnlyMode) {
      return true;
    } else {
      return false;
    }
  }

  viewGridDeleteIcon = (e: any) => {

    let alreadyServed = e.row.data.alreadyServedInClock;
    let state = e.row.data.state;

    if (!alreadyServed
      && state !== 1
      && this.catalog && this.catalog.enableDelete
      && !this.catalog.viewOnlyMode) {
      return true;
    } else {
      return false;
    }
  }

  stripHTML(text: string) {
    return text.replace(/<.*?>/gm, '');
  }

  validateForm() {
    if (this.validationFormGroup && this.validationFormGroup.instance) {
      const res = this.validationFormGroup.instance.validate();
      if (res.isValid) {
        this.isFormValid = true;
      } else {
        this.isFormValid = false;
      }
    }

    this.validateDateTimes();
  }

  validateDateTimes() {
    this.lot.lotPropertyGroups.forEach(group => {
      if (!group.isHidden) {
        group.lotPropertyGroupRows.forEach(row => {
          const invalidDate = row.lotProperties.some(lotProperty =>
            lotProperty.propertyTypeId === ProductPropertyTypes.DATE &&
            lotProperty.productPropertyEditorSelectionDetails.required &&
            !lotProperty.dateTimeValue);
          if (invalidDate)
            this.isFormValid = false;
        });
      }
    });
  }

  onImageChanged(e) {
    this.spinner.show();
    const file = <File>e.srcElement.files[0];

    if (file) {
      const formData = new FormData();
      formData.append('file', file);

      this.dataService.addLotImage(this.catalog.auctionId, formData).subscribe((imgSrc: any) => {

        if (imgSrc.length !== 0) {
          this.spinner.hide();
        }
        this.largeImageSrc = imgSrc;

        this.focusedProperty.displayUrlTextValue = this.focusedProperty.urlValue = imgSrc;
        this.focusedProperty.displayUrlImageValue = imgSrc + '-thumbnail';
        this.focusedProperty.valueChanged = true;
        this.focusedProperty.changed = true;
        this.onPropertyChanged(this.focusedProperty);
      });
    } else {
      this.spinner.hide();
    }
  }

  showLargerImage(e, imgSrc: string) {
    this.largerImageVisible = true;
    this.largeImageSrc = imgSrc.replace('-thumbnail', '');
  }

  hideLargerImage(e) {
    this.largerImageVisible = false;
  }

  clearImageProperty() {
    this.focusedProperty.urlValue = null;

    this.focusedProperty.valueChanged = true;
    this.focusedProperty.changed = true;
    this.onPropertyChanged(this.focusedProperty);

    this.focusedProperty.displayUrlImageValue = this.emptyPictureSource;
    this.focusedProperty.displayUrlTextValue = null;
  }

  switchEditorMode() {
    let supplyEditorMode = this.cookieService.get(Cookies.SUPPLY_EDITOR_MODE);
    if (supplyEditorMode && supplyEditorMode === 'desktop') {
      if (this.lot.lotId !== 0) {
        if (this.isForecastCatalog) {
          this.router.navigate(['/auction/catalogs/' + this.id + '/supplyforecastlots/' + this.catalogId + '/editor'], { queryParams: { masterDetailsLotId: this.masterDetailsLotId, lotId: this.lot.lotId } });
        } else {
          this.router.navigate(['/auction/catalogs/' + this.id + '/supplylots/' + this.catalogId + '/editor'], { queryParams: { masterDetailsLotId: this.masterDetailsLotId, lotId: this.lot.lotId } });
        }
      } else {
        if (this.isForecastCatalog) {
          this.router.navigate(['/auction/catalogs/' + this.id + '/supplyforecastlots/' + this.catalogId + '/editor'], { queryParams: { masterDetailsLotId: this.masterDetailsLotId } });
        } else {
          this.router.navigate(['/auction/catalogs/' + this.id + '/supplylots/' + this.catalogId + '/editor'], { queryParams: { masterDetailsLotId: this.masterDetailsLotId } });
        }
      }
      this.enableTouchScreenLayout = true;
      this.isVertical = false;
      this.cookieService.set(Cookies.SUPPLY_EDITOR_MODE, 'touch');
      this.isTouchMode = true;
    } else {
      if (this.isForecastCatalog) {
        this.router.navigate(['/auction/catalogs/' + this.id + '/supplyforecastlots/' + this.catalogId], { queryParams: { masterDetailsLotId: this.masterDetailsLotId } });
      } else {
        this.router.navigate(['/auction/catalogs/' + this.id + '/supplylots/' + this.catalogId], { queryParams: { masterDetailsLotId: this.masterDetailsLotId } });
      }
      this.enableTouchScreenLayout = false;
      this.isVertical = true;
      this.cookieService.set(Cookies.SUPPLY_EDITOR_MODE, 'desktop');
      this.isTouchMode = false;
    }
  }

  goBack() {
    if (this.product.hasSupplyMasterDetails) {
      if (this.isForecastCatalog) {
        this.router.navigate(['/auction/catalogs/' + this.id + '/forecastmasterdetails/' + this.catalog.catalogId]);
      } else {
        this.router.navigate(['/auction/catalogs/' + this.id + '/masterdetails/' + this.catalog.catalogId]);
      }
    } else {
      if (this.isForecastCatalog) {
        this.router.navigate(['/auction/forecastcatalogs/' + this.id]);
      } else {
        this.router.navigate(['/auction/supplycatalogs/' + this.id]);
      }
    }
  }

  changeWeighingScaleState(productProperty: any, ev: any) {
    if (ev.event) {
      if (ev.value) {
        let weighingScaleConnection = this.weighingScaleConnections.find(_ => _.productProperties.includes(productProperty.productPropertyId));
        if (weighingScaleConnection && weighingScaleConnection.weight)
          productProperty.decimalValue = weighingScaleConnection.weight;

        let weighingScaleProperties = this.componentsWithProperties.filter(_ => _.property.productPropertyEditorSelectionDetails.useWeighingScale);
        weighingScaleProperties.forEach(prop => {
          if (prop.property.productPropertyId === productProperty.productPropertyId) {
            prop.property.isWeighingScaleOn = true;
            if (this.lot.lotId === 0 || this.addNew)
              this.cookieService.set(Cookies.WEIGHING_SCALE_PROPERTY_ID, productProperty.productPropertyId);
            if (weighingScaleConnection.stable) {
              this.onPropertyChanged(prop.property)
            }
          } else {
            prop.property.isWeighingScaleOn = false;
          }
        })
      } else {
        productProperty.isWeighingScaleOn = false;
        if (this.lot.lotId === 0 || this.addNew)
          this.cookieService.set(Cookies.WEIGHING_SCALE_PROPERTY_ID, '0');
      }
    }
  }

  setWeighingScaleState(productProperty: any) {
    if (this.lot.lotId === 0 || this.addNew) {
      let weighingScalePropertyId = this.cookieService.get(Cookies.WEIGHING_SCALE_PROPERTY_ID);
      if (weighingScalePropertyId === "") {
        weighingScalePropertyId = productProperty.productPropertyId;
        this.cookieService.set(Cookies.WEIGHING_SCALE_PROPERTY_ID, productProperty.productPropertyId);
      }
      if (weighingScalePropertyId && parseInt(weighingScalePropertyId) === productProperty.productPropertyId)
        productProperty.isWeighingScaleOn = true;
    } else {
      productProperty.isWeighingScaleOn = false;
    }
  }

  add() { }
}
